<template>
  <div class v-if="detail != null">
    <Toast />

    <div class="card">
      <h5>
        <b>Detail Item</b>
      </h5>
      <div class="p-grid">
        <div class="p-col-12">
          <table class="table table-responsive">
            <thead class="thead-light">
              <tr>
                <th>Gambar</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(an_item, k) in detail.item_images" :key="k">
                <td v-bind:style="{ width: '40%' }">
                  <img
                    :src="an_item.url"
                    :alt="an_item.name"
                    v-bind:style="{ width: '50%' }"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <Dialog
            :visible.sync="setDefaultDialog"
            :style="{ width: '450px' }"
            header="Konfirmasi Default"
            :modal="true"
          >
            <div class="confirmation-content">
              <i
                class="pi pi-exclamation-triangle p-mr-3"
                style="font-size: 2rem"
              />
              <span v-if="itemImages">
                <span v-if="itemImages.name">
                  Apakah kamu ingin menyetel default
                  <b>{{ itemImages.name }}</b
                  >?
                </span>
                <span v-else>
                  Apakah kamu ingin menyetel default gambar ini?
                </span>
              </span>
            </div>
            <template #footer>
              <Button
                label="Tidak"
                icon="pi pi-times"
                class="p-button-text"
                @click="setDefaultDialog = false"
              />
              <Button
                label="Ya"
                icon="pi pi-check"
                class="p-button-text"
                @click="setDefault(itemImages.id)"
              />
            </template>
          </Dialog>

          <Dialog
            :visible.sync="deleteDialog"
            :style="{ width: '450px' }"
            header="Konfirmasi Hapus"
            :modal="true"
          >
            <div class="confirmation-content">
              <i
                class="pi pi-exclamation-triangle p-mr-3"
                style="font-size: 2rem"
              />
              <span v-if="itemImages">
                <span v-if="itemImages.name">
                  Apakah kamu ingin menghapus gambar
                  <b>{{ itemImages.name }}</b
                  >?
                </span>
                <span v-else>
                  Apakah kamu ingin menyetel menghapus gambar ini?
                </span>
              </span>
            </div>
            <template #footer>
              <Button
                label="Tidak"
                icon="pi pi-times"
                class="p-button-text"
                @click="deleteDialog = false"
              />
              <Button
                label="Ya"
                icon="pi pi-check"
                class="p-button-text"
                @click="deleteImage(itemImages.id)"
              />
            </template>
          </Dialog>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="p-grid">
        <div class="p-col p-mb-5">
          <label for="">Kode</label>
          <div>{{ detail.item.code }}</div>
        </div>

        <div class="p-col p-mb-5">
          <label for="">Nama</label>
          <div>{{ detail.item.name }}</div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col p-mb-5">
          <label for="">Supplier</label>
          <div>{{ detail.item.supplier }}</div>
        </div>

        <div class="p-col p-mb-5">
          <label for="">Kategori</label>
          <div>{{ detail.item.category }}</div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col p-mb-5">
          <label for="">Deskripsi</label>
          <div>{{ detail.item.description }}</div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col p-mb-5">
          <label for="">Harga Awal</label>
          <div>{{ detail.item.init_price | currency }}</div>
        </div>

        <div class="p-col p-mb-5">
          <label for="">Harga Modal</label>
          <div>{{ detail.item.cap_price | currency }}</div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col p-mb-5">
          <label for="">Harga Minimum</label>
          <div>{{ detail.item.min_price | currency }}</div>
        </div>

        <div class="p-col p-mb-5">
          <label for="">Harga Spesial</label>
          <div>{{ detail.item.special_price | currency }}</div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col p-mb-5">
          <label for="">Harga</label>
          <div>{{ detail.item.price | currency }}</div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col p-mb-5">
          <label for="">Diskon</label>
          <div>{{ detail.item.discount }}%</div>
        </div>

        <div class="p-col p-mb-5">
          <label for="">Stok</label>
          <div>{{ detail.item.stock }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      expandedRows: [],
      detail: null,
      itemImages: {},
      deleteDialog: false,
      setDefaultDialog: false,
      loading: {
        upload: false,
        setDefaultButton: false,
        deleteButton: false
      }
    }
  },
  computed: mapState(['user', 'isAuthenticated']),
  mounted() {
    this.getDetails()
  },
  methods: {
    confirmDelete(itemImages) {
      this.itemImages = itemImages
      this.deleteDialog = true
    },

    deleteImage(id) {
      this.loading.deleteButton = true
      this.$http
        .delete(`${process.env.VUE_APP_API_URL}/dashboard/item-images/${id}`)
        .then(response => {
          this.loading.deleteButton = false
          this.deleteDialog = false
          if (response.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Berhasil',
              detail: 'Gambar berhasil di hapus',
              life: 3000
            })
            this.getDetails()
          }
        })
    },

    confirmSetDefault(itemImages) {
      this.itemImages = itemImages
      this.setDefaultDialog = true
    },

    setDefault(id) {
      this.loading.setDefaultButton = true
      this.$http
        .post(
          `${process.env.VUE_APP_API_URL}/dashboard/item-images/${id}/default`
        )
        .then(response => {
          this.loading.setDefaultButton = false
          this.setDefaultDialog = false
          if (response.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Berhasil',
              detail: 'Gambar berhasil di ubah default',
              life: 3000
            })
            this.getDetails()
          }
        })
    },

    getDetails() {
      this.$http
        .get(
          `${process.env.VUE_APP_API_URL}/dashboard/items/${this.$route.query.id}`
        )
        .then(response => {
          if (response.status === 200) {
            this.detail = response.data.data
            this.detail.item_images = this.detail.item_images.map(image => {
              // regex for extracting the ID
              const id = image.url.match(/[-\w]{25,}/)
              const newUrl =
                id != null ? `https://lh3.google.com/u/0/d/${id}` : ''
              return { ...image, url: newUrl }
            })
          }
        })
    },

    onUpload(event) {
      this.loading.upload = true
      var formData = new FormData()
      event.files.forEach(element => {
        formData.append('files', element)
      })
      this.$http
        .post(
          `${process.env.VUE_APP_API_URL}/dashboard/item-images?item_id=${this.$route.query.id}`,
          formData
        )
        .then(response => {
          this.loading.upload = false
          if (response.status == 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Berhasil',
              detail: 'File berhasil di upload',
              life: 3000
            })
            this.getDetails()
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Gagal',
              detail: 'File gagal di upload',
              life: 3000
            })
          }
        })
    }
  }
}
</script>

<style scoped lang="scss">
.loading-bar {
  position: relative;
  top: 4.7rem;
}

/deep/ .p-progressbar {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

.p-datatable .p-column-filter {
  display: none;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

/deep/ .p-datatable.p-datatable-outlets {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-outlets .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.customer-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-qualified {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-unqualified {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-negotiation {
    background: #feedaf;
    color: #8a5340;
  }

  &.status-new {
    background: #b3e5fc;
    color: #23547b;
  }

  &.status-renewal {
    background: #eccfff;
    color: #694382;
  }

  &.status-proposal {
    background: #ffd8b2;
    color: #805b36;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: 0.5rem;
  }
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.orders-subtable {
  padding: 1rem;
}

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}
input:disabled {
  color: black;
}

.order-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.order-delivered {
    background: #c8e6c9;
    color: #256029;
  }

  &.order-cancelled {
    background: #ffcdd2;
    color: #c63737;
  }

  &.order-pending {
    background: #feedaf;
    color: #8a5340;
  }

  &.order-returned {
    background: #eccfff;
    color: #694382;
  }
}

@media screen and (max-width: 960px) {
  /deep/ .p-datatable {
    &.p-datatable-outlets {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        > td {
          text-align: left;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: 0.4rem;
            min-width: 30%;
            display: inline-block;
            margin: -0.4rem 1rem -0.4rem -0.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}
</style>
