import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { VueAuthenticate } from 'vue-authenticate'

Vue.use(VueAxios, axios)

const vueAuthInstance = new VueAuthenticate(Vue.axios, {
  baseUrl: process.env.VUE_APP_API_URL,
  loginUrl: '/dashboard/auth/login',
  registerUrl: '',
  logoutUrl: '/auth/logout',
  tokenPath: 'access_token',
  providers: {}
})

export default vueAuthInstance
