<template>
  <div class="stock-opname-detail">
    <div class="card">
      <!-- HEADER -->
      <div class="card">
        <div class="p-grid">
          <div class="p-col-2 image">
            <img :src="ModeLogo" alt="ModeFashionLogo" />
          </div>
          <div class="p-col-10" style="margin-left: -10px">
            <h4 style="font-size: 15px">
              <b>MODE FASHION GROUP</b>
            </h4>
            <h6 style="font-size: 11px; margin-top: -15px">Medan</h6>
            <h6 style="font-size: 11px; margin-top: -15px">
              Telp. (061) 6643780
            </h6>
          </div>
        </div>
      </div>
      <div class="border-header" style="margin-bottom: 0.5rem"></div>
      <!-- akhir header -->

      <div class="title" style="font-size: 14px">Review Stok Opname</div>
      <div class="p-grid" style="margin-top: -2vh">
        <div class="p-col-3">
          <label>Status</label>
          <div class="sub-title">{{ stock_opname.status }}</div>
        </div>
        <div class="p-col-3">
          <label>Waktu Mulai</label>
          <div class="sub-title">{{ stock_opname.start | dateTime }}</div>
        </div>
        <div class="p-col-3">
          <label>Total Kekurangan</label>
          <div class="sub-title">
            {{ stock_opname.deficiency_total | number }}
          </div>
        </div>
        <div class="p-col-3">
          <label>Total Kelebihan</label>
          <div class="sub-title">{{ stock_opname.excess_total | number }}</div>
        </div>
      </div>

      <div class="p-grid">
        <div class="p-col-3"></div>
        <div class="p-col-3">
          <label>Waktu Selesai</label>
          <div class="sub-title">{{ stock_opname.end | dateTime }}</div>
        </div>
        <div class="p-col-3">
          <label>Nilai Kekurangan</label>
          <div class="sub-title">
            {{ stock_opname.deficiency_value | currency }}
          </div>
        </div>
        <div class="p-col-3">
          <label>Nilai Kelebihan</label>
          <div class="sub-title">
            {{ stock_opname.excess_value | currency }}
          </div>
        </div>
      </div>

      <div class="p-mt-5">
        <table class="table-bordered table-responsive detail-bon">
          <thead class="thead-light" style="font-size: 11px">
            <tr>
              <th>No</th>
              <th>Kode Barang</th>
              <th>Nama Barang</th>
              <!-- <th>Stock</th> -->
              <th>Selisih</th>
              <th>Harga Ganti</th>
              <th>Jumlah Ganti</th>
              <!-- <th>Tipe</th> -->
            </tr>
          </thead>
          <tbody style="font-size: 11px">
            <tr v-for="(item, k) in stock_opname_detail" :key="k">
              <td>{{ k + 1 }}</td>
              <td>{{ item.item_code }}</td>
              <td>{{ item.item_name }}</td>
              <!-- <td>{{ item.stock }}</td> -->
              <td>{{ item.difference }}</td>
              <td>{{ item.replacement_price | number }}</td>
              <td>{{ item.total_replacement_price | number }}</td>
              <!-- <td>
                {{ item.type === 'DEFICIENCY' ? 'Kekurangan' : 'Kelebihan' }}
              </td> -->
            </tr>
            <tr>
              <td colspan="5"></td>
              <td>{{ data_opname.total_replacement_price | number }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ModeLogo from '../../assets/images/mode_logo.png'

export default {
  props: ['data_opname', 'stock_opname', 'stock_opname_detail'],

  data() {
    return {
      ModeLogo: ModeLogo
    }
  },
  mounted() {
    setTimeout(function () {
      window.print()
    }, 500)
    setTimeout(function () {
      window.history.back()
    }, 500)
  },
  methods: {}
}
</script>

<style scoped>
.stock-opname-detail {
  font-family: monospace;
  width: 100%;
  margin-top: -45px;
  margin-left: auto; /* to center the div */
  margin-right: auto; /* to center the div */
  box-sizing: border-box;
  overflow: hidden;
}

.border-header {
  border-top: 2px solid black;
  width: 100%;
  margin-top: -3.5vh;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.image {
  /* margin-top: -5px; */
  margin-left: -40px;
  width: 65px;
}

.title {
  text-align: center;
}

label {
  font-size: 12px;
}

.sub-title {
  font-size: 11px;
}

.detail-bon {
  margin-left: auto;
  margin-right: auto;
}

table {
  padding: 0;
  margin: 0;
  width: 100%;
}

th,
td {
  padding: 5px;
  text-align: center;
}
</style>
