<template>
  <div class="layout-profile">
    <div>
      <img :src="require(`@/assets/images/profile.png`)" alt />
    </div>
    <button class="p-link layout-profile-link" @click="onClick">
      <span class="username">{{ this.user.name }}</span>
      <i class="pi pi-cog"></i>
      <br />
    </button>
    <transition name="layout-submenu-wrapper">
      <ul v-show="expanded">
        <li>
          <router-link :to="{ name: 'change-password', params: {} }">
            <button class="p-link">
              <i class="pi pi-key"></i>
              <span>Ubah Password</span>
            </button>
          </router-link>
        </li>
        <li>
          <button class="p-link" @click="doLogout">
            <i class="pi pi-sign-out"></i>
            <span>Logout</span>
          </button>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: mapState(['user', 'isAuthenticated']),
  watch: {
    user() {
      this.getAuthUser()
    }
  },
  data() {
    return {
      expanded: false,
      role: ''
    }
  },
  mounted() {
    this.getAuthUser()
  },
  methods: {
    onClick(event) {
      this.expanded = !this.expanded
      event.preventDefault()
    },
    doLogout() {
      this.$store
        .dispatch('logout', { requestOptions: { method: 'POST' } })
        .then(() => {
          this.$router.go()
          this.$router.push({ name: 'login' })
        })
    },
    getAuthUser() {
      if (this.user) {
        this.role = this.user.role
      }
    }
  }
}
</script>

<style scoped></style>
