<template>
  <div class="card">
    <Toast />

    <div class="title">Buat Stock Opname</div>

    <div
      class="p-grid p-fluid p-mt-5"
      v-if="!stock_opname_status || stock_opname_status === 'END'"
    >
      <div class="p-col-12 p-md-6 p-lg-3">
        <Button label="Mulai Stock Opname" @click="start_dialog = true" />
      </div>
    </div>

    <div class="p-grid" v-else>
      <div class="p-grid p-col-12">
        <div class="p-col-4">
          <label>Status</label>
          <div
            v-if="stock_opname_status == 'START'"
            style="color: green; font-weight: bold"
          >
            {{ stock_opname_status }}
          </div>
          <div
            v-else-if="stock_opname_status == 'END'"
            style="color: red; font-weight: bold"
          >
            {{ stock_opname_status }}
          </div>
          <div v-else style="color: blue; font-weight: bold">
            {{ stock_opname_status }}
          </div>
        </div>
        <div class="p-col-4">
          <label>Tanggal Mulai</label>
          <div>{{ stock_opname_start }}</div>
        </div>
        <div class="p-col-4">
          <label>Tanggal Selesai</label>
          <div>-</div>
        </div>
      </div>

      <div class="p-col-12 p-formgroup-inline p-mt-5">
        <div class="p-field">
          <InputText
            v-model="barcode"
            placeholder="Barcode"
            ref="barcode"
            @keyup.enter="
              getItem()
              nextItemFocus($event)
            "
          />
        </div>
        <Button type="button" label="Cari" @click="getItem" />
      </div>

      <div class="p-col-12 p-fluid">
        <table class="table table-responsive">
          <thead class="thead-light">
            <tr>
              <th>Gambar</th>
              <th>Kode</th>
              <th>Nama</th>
              <th>Kategori</th>
              <th>Supplier</th>
              <th class="text-center">Stok</th>
              <th>Real Stock</th>
              <th class="text-center">Selisih</th>
            </tr>
          </thead>
          <tbody v-if="item.id != 0">
            <tr>
              <td>
                <img
                  :src="item.thumbnail ? item.thumbnail : no_image"
                  style="width: 100px"
                />
              </td>
              <td>{{ item.code }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.category }}</td>
              <td>{{ item.supplier }}</td>
              <td class="text-center" style="font-size: 18px">
                {{ item.stock }}
              </td>
              <td style="max-width: 80px">
                <InputText
                  :min="0"
                  type="number"
                  v-model="form.real_stock"
                  ref="real_stock"
                  style="font-size: 18px"
                  @keyup.enter="updateStockOpnameDetail"
                />
              </td>
              <td class="text-center" style="font-size: 18px">
                {{ item.stock - form.real_stock }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="p-grid p-fluid p-col-12 p-justify-center" v-if="item.id != 0">
        <div class="p-col-12 p-md-6 p-lg-3">
          <!-- <Button
            type="button"
            label="Submit"
            @click="updateStockOpnameDetail"
          /> -->
          <Button type="button" label="Submit" @click="updateStockOpnameDetail">
            <span v-if="loading_submit">
              <i class="pi pi-spin pi-spinner" style="fontsize: 12px"></i>
            </span>
          </Button>
        </div>
      </div>
    </div>

    <Dialog
      :visible.sync="start_dialog"
      :style="{ width: '450px' }"
      header="Konfirmasi"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-2 p-text-bold" />
        Kamu yakin ingin memulai proses stock opname?
      </div>
      <template #footer>
        <Button
          label="Tidak"
          icon="pi pi-times"
          class="p-button-text"
          @click="start_dialog = false"
        />
        <Button
          label="Ya"
          icon="pi pi-check"
          class="p-button-text"
          @click="startStockOpname"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import noImage from '../../assets/images/no-image.png'

export default {
  computed: {
    ...mapState(['user', 'isAuthenticated'])
  },
  watch: {
    user() {
      this.getAuthUser()
    }
  },
  data() {
    return {
      stock_opname_status: '',
      stock_opname_start: '',
      stock_opname_end: '',
      start_dialog: false,
      loading_submit: false,
      barcode: '',
      item: {
        id: 0,
        name: '',
        image_url: '',
        category: '',
        supplier: '',
        stock: 0
      },
      form: {
        item_id: 0,
        real_stock: 0
      },
      no_image: noImage
    }
  },
  mounted() {
    this.getAuthUser()
    setTimeout(this.setFocus, 1000)
  },
  methods: {
    setFocus() {
      if (this.$refs.barcode) {
        this.$refs.barcode.$el.focus()
      }
    },
    getAuthUser() {
      if (this.user && this.user.stock_opname) {
        this.stock_opname_status = this.user.stock_opname.status
        this.stock_opname_start = this.user.stock_opname.start
        this.stock_opname_end = this.user.stock_opname.end
      }
    },
    startStockOpname() {
      this.start_dialog = false
      this.$http
        .post(`${process.env.VUE_APP_API_URL}/pos/stock-opname/start`)
        .then(response => {
          if (response.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Berhasil memulai stock opname',
              life: 3000
            })

            this.$store
              .dispatch('setUser', { requestOptions: { method: 'GET' } })
              .then(() => {
                this.getAuthUser()
                setTimeout(() => {
                  this.setFocus()
                }, 500)
              })
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Gagal memulai stock opname',
              life: 3000
            })
          }
        })
    },
    getItem() {
      this.item = {
        id: 0,
        name: '',
        image_url: '',
        category: '',
        supplier: '',
        stock: 0
      }
      this.form = {
        item_id: 0,
        real_stock: 0
      }

      if (this.barcode === '') {
        this.$toast.add({
          severity: 'error',
          summary: 'Barcode tidak boleh kosong',
          life: 3000
        })
        this.setFocus()
        return
      }

      this.$http
        .get(
          `${process.env.VUE_APP_API_URL}/outlet-items?show_empty_stock=true&barcode=${this.barcode}`
        )
        .then(response => {
          if (response.status === 200) {
            if (response.data.data.total === 0) {
              this.$toast.add({
                severity: 'error',
                summary: 'Barcode Salah',
                life: 3000
              })
              this.clearForm()
              this.setFocus()
            } else {
              this.item = { ...this.item, ...response.data.data.items[0] }
              this.form = {
                item_id: response.data.data.items[0].id,
                real_stock: response.data.data.items[0].temporaryStock
                  ? response.data.data.items[0].temporaryStock
                  : 0
              }
              setTimeout(() => {
                this.$refs.real_stock.$el.focus()
              }, 100)
              // this.getStockOpnameDetail()
            }
          }
        })
    },
    getStockOpnameDetail() {
      this.$http
        .get(
          `${process.env.VUE_APP_API_URL}/pos/stock-opname-detail?item_id=${this.item.id}`
        )
        .then(response => {
          if (response.status === 200) {
            if (response.data.data.stock_opname_detail) {
              if (response.data.data.stock_opname_detail.type == 'DEFICIENCY') {
                this.form.real_stock -=
                  response.data.data.stock_opname_detail.difference
              } else {
                this.form.real_stock +=
                  response.data.data.stock_opname_detail.difference
              }
            }
          }
        })
    },
    nextItemFocus(event) {
      console.log(
        event.target.parentElement.parentElement.nextElementSibling.children[0]
          .children[1].children[0].children[6].children[0]
      )
      event.target.parentElement.parentElement.nextElementSibling.children[0].children[1].children[0].children[6].children[0].focus()
    },
    updateStockOpnameDetail() {
      this.form.item_id = this.item.id
      this.form.real_stock = parseInt(this.form.real_stock)
      if (this.form.real_stock < 0) {
        this.$toast.add({
          severity: 'error',
          summary: 'Real Stock tidak boleh kurang dari 0',
          life: 3000
        })
        return
      } else {
        this.loading_submit = true
      }
      this.$http
        .post(
          `${process.env.VUE_APP_API_URL}/pos/stock-opname-details`,
          this.form
        )
        .then(response => {
          if (response.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Berhasil menyimpan data stock opname',
              life: 3000
            })

            // this.getItem()
            // this.getStockOpnameDetail()
            this.loading_submit = false
            this.clearForm()
            this.$refs.barcode.$el.focus()
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Gagal menyimpan data stock opname',
              life: 3000
            })
            this.loading_submit = false
          }
        })
      // }
    },
    clearForm() {
      this.barcode = ''
      this.item = {
        id: 0,
        name: '',
        image_url: '',
        category: '',
        supplier: '',
        stock: ''
      }
      this.form = {
        item_id: 0,
        real_stock: 0
      }
      this.noImage = noImage
    }
  }
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
