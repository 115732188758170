<template>
  <div class="layout-topbar">
    <button class="p-link layout-menu-button" @click="onMenuToggle">
      <span class="pi pi-bars"></span>
    </button>
    <div class="layout-topbar-icons"></div>
  </div>
</template>

<script>
export default {
  watch: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {
    onMenuToggle(event) {
      this.$emit('menu-toggle', event)
    }
  }
}
</script>
