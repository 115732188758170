<template>
  <div class="layout-footer">
    <span class="footer-text">&copy; 2021</span>
    <a href="https://coreinitiative.id">
      <span class="footer-text" style="margin-left: 5px">
        Core Initiative Studio.
      </span>
    </a>
    <span class="footer-text"> Themes &amp; Layout by </span>
    <a href="https://www.primefaces.org/sigma-vue/">
      <span class="footer-text">PrimeVue Sigma</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped></style>
