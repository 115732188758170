<template>
  <div class="card">
    <div class="title">Ringkasan</div>
    <div class="p-grid">
      <div class="p-col">
        <label>Kasir</label>
        <div>{{ summary.cashier.name }}</div>
      </div>

      <div class="p-col">
        <label>Waktu Mulai</label>
        <div>{{ moment(summary.shift.opened_at).format('DD-MM-YYYY') }}</div>
      </div>
      <div class="p-col">
        <label>Cash Awal</label>
        <div>{{ summary.shift.opening_balance | currency }}</div>
      </div>
      <div class="p-col">
        <label>Tanggal Audit</label>
        <div>{{ moment(summary.shift.audit_date).format('DD-MM-YYYY') }}</div>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col">
        <label>Status</label>
        <div>{{ summary.shift.status }}</div>
      </div>
      <div class="p-col">
        <label>Waktu Selesai</label>
        <div>{{ moment(summary.shift.closed_at).format('DD-MM-YYYY') }}</div>
      </div>
      <div class="p-col">
        <label>Cash Akhir</label>
        <div>{{ summary.shift.closing_balance | currency }}</div>
      </div>
      <div class="p-col"></div>
    </div>

    <h6><b>Transaksi</b></h6>
    <div>
      <table class="table table-responsive">
        <thead class="thead-light">
          <tr>
            <th>No</th>
            <th>Kode Kategori</th>
            <th>Nama Kategori</th>
            <th>Jumlah Terjual</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(a_row, k) in summary.transaction_summary" :key="k">
            <td>{{ k + 1 }}</td>
            <td>{{ a_row.category_code }}</td>
            <td>{{ a_row.category_name }}</td>
            <td>{{ a_row.total_sold }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h6><b>Pembayaran</b></h6>
    <div>
      <table class="table table-responsive">
        <thead class="thead-light">
          <tr>
            <th>No</th>
            <th>Metode Pembayaran</th>
            <th>Jumlah</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(a_row, k) in summary.payment_summary" :key="k">
            <td>{{ k + 1 }}</td>
            <td>{{ a_row.payment_method }}</td>
            <td>{{ a_row.total_amount | currency }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
var moment = require('moment')

export default {
  props: ['summary'],
  data() {
    return {
      moment: moment
    }
  },
  mounted() {
    setTimeout(function () {
      window.print()
    }, 500)
    setTimeout(function () {
      window.history.back()
    }, 500)
  }
}
</script>

<style scoped lang="scss"></style>
