<template>
  <div class="card">
    <Toast />
    <div class="title">Voucher Pengembalian</div>
    <div class="p-grid p-fluid p-formgroup-inline">
      <div class="p-field p-col-4">
        <InputText
          v-model="invoice_number"
          placeholder="Nomor Tagihan"
          ref="invoice_number"
          v-shortkey.push="['enter']"
          @shortkey="getListTransaction()"
        />
      </div>
      <div class="p-col-2">
        <Button label="Cek Invoice" @click="getListTransaction" />
      </div>
    </div>

    <div class="p-grid p-fluid p-col">
      <table class="table table-responsive">
        <thead class="thead-light">
          <tr v-if="vouchers">
            <th>Kode</th>
            <th>Nominal</th>
            <th>Tipe</th>
            <th>Expired</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(an_item, k) in vouchers" :key="k">
            <td>{{ an_item.code }}</td>
            <td>{{ an_item.amount | currency }}</td>
            <td>{{ an_item.type }}</td>
            <td>{{ an_item.expired_date | date }}</td>
            <td v-if="an_item.trans_id_redeemer == ''">
              <!-- <button class="btn btn-primary" @click="getListTransaction">
                Gunakan
              </button> -->
              <router-link
                :to="{
                  name: 'pos',
                  params: { voucher_code_used: an_item.code }
                }"
                class="btn"
              >
                <Button label="Gunakan" />
              </router-link>
              <button
                class="btn btn-danger"
                style="margin-left: 10px"
                @click="confirmDelete(an_item)"
              >
                Hapus
              </button>
            </td>
            <td v-else>
              <span>
                <b style="color: red">Unavailable</b>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="p-grid p-fluid p-col">
      <table class="table table-responsive">
        <thead class="thead-light">
          <tr v-if="dataItem.transaction != 0">
            <th>Refund</th>
            <th>Kode barang</th>
            <th>Nama</th>
            <th>Terbeli</th>
            <th>Harga Awal</th>
            <th>Diskon Item</th>
            <th>Diskon Transaksi</th>
            <th>Voucher Shopping</th>
            <th>Harga</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(an_item, k) in dataItem.transaction" :key="k">
            <td>
              <div>
                <InputText
                  v-model="an_item.jml"
                  type="number"
                  style="width: 50pt"
                  min="0"
                  :max="an_item.qty"
                  @keyup.prevent="qtyChanges(k)"
                />
              </div>
              <small class="p-error" v-if="isError.transaction_items[k].qty">{{
                error_msg.transaction_items[k].qty
              }}</small>
            </td>
            <td>{{ an_item.item_code }}</td>
            <td>{{ an_item.item_name }}</td>
            <td>{{ an_item.qty }}</td>
            <td>{{ an_item.init_price | currency }}</td>
            <td>{{ an_item.discount | currency }}</td>
            <td>{{ an_item.discount_transaction | currency }}</td>
            <td>{{ an_item.voucher_shopping | currency }}</td>
            <td>{{ an_item.price | currency }}</td>
            <td>
              <b>{{ an_item.total_price | currency }}</b>
            </td>
          </tr>
          <tr v-if="dataItem.transaction.length != 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><b>Total Refund</b></td>
            <td>
              <b>{{ allPrice | currency }}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="p-grid p-fluid p-justify-center"
      v-if="dataItem.transaction.length != 0"
    >
      <div class="p-col-12 p-md-6 p-lg-3">
        <Button
          :disabled="loading.generateButton"
          @click="confirmGenerate()"
          label="Buat"
        />
      </div>
    </div>

    <!-- <div class="p-grid p-fluid p-col" v-if="dataItem.voucher">
      <div class="p-col-3">
        <label>Kode</label>
        <div>{{ dataItem.voucher.code }}</div>
      </div>

      <div class="p-col-3">
        <label>Jumlah</label>
        <div>{{ dataItem.voucher.amount | currency }}</div>
      </div>

      <div class="p-col-3">
        <label>Tipe</label>
        <div>{{ dataItem.voucher.type }}</div>
      </div>

      <div class="p-col-3">
        <label>Expired</label>
        <div>{{ dataItem.voucher.expired_date | date }}</div>
      </div>

      <div
        class="p-grid p-fluid p-col-12 p-justify-center p-mt-3"
        v-if="!dataItem.voucher.trans_id_redeemer"
      >
        <div class="p-col-6 p-md-3 p-lg-3">
          <Button
            label="Hapus"
            @click="confirmDelete(dataItem.voucher)"
            :disabled="loading.deleteButton"
          />
        </div>
        <div class="p-col-6 p-md-3 p-lg-3">
          <router-link
            :to="{
              name: 'pos',
              params: { voucher_code_used: dataItem.voucher.code }
            }"
          >
            <Button label="Gunakan" />
          </router-link>
        </div>
      </div>
    </div> -->

    <Dialog
      :visible.sync="generateDialog"
      :style="{ width: '450px' }"
      header="Konfirmasi"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-2 p-text-bold" />
        Kamu yakin ingin membuat voucher refund ini?
      </div>
      <template #footer>
        <Button
          label="Tidak"
          icon="pi pi-times"
          class="p-button-text"
          @click="generateDialog = false"
        />
        <Button
          label="Iya"
          icon="pi pi-check"
          class="p-button-text"
          @click="generateTransaction"
        />
      </template>
    </Dialog>

    <Dialog
      :visible.sync="deleteDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="dataItem.voucher">
          Are you sure you want to delete
          <b>{{ dataItem.voucher.code }}</b
          >?
        </span>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteVoucher(dataItem.voucher.id)"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      invoice_number: '',
      dataItem: {
        transaction: [],
        voucher: null
      },
      vouchers: null,
      loading: {
        deleteButton: false,
        generateButton: false
      },
      deleteDialog: false,
      generateDialog: false,
      jml: null,
      total_price: 0,
      allPrice: 0,
      inputData: {
        transaction_items: []
      },
      isError: {
        transaction_items: []
      },
      error_msg: {
        transaction_items: []
      }
    }
  },
  computed: mapState(['user', 'isAuthenticated']),
  watch: {
    user() {
      this.getAuthUser()
    }
  },
  mounted() {
    setTimeout(this.setFocus, 1000)
  },
  methods: {
    getAuthUser() {
      console.log(this.user)
    },
    setFocus() {
      this.$refs.invoice_number.$el.focus()
    },
    formatPrice(value) {
      if (value != undefined) {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      } else {
        return 'Rp 0,00'
      }
    },
    confirmDelete(voucher) {
      this.dataItem.voucher = voucher
      this.deleteDialog = true
    },
    confirmGenerate() {
      this.generateDialog = true
    },
    generateTransaction() {
      this.inputData.transaction_items = []
      var flag = true
      for (var i = 0; i < this.dataItem.transaction.length; i++) {
        var data = this.dataItem.transaction[i]
        if (data.jml > data.qty) {
          this.isError.transaction_items[i].qty = true
          this.error_msg.transaction_items[i].qty = 'Quantity not enough'
          flag = false
        }
        if (data.jml > 0) {
          this.inputData.transaction_items.push({ id: data.id, qty: +data.jml })
        }
      }

      if (flag) {
        this.$http
          .post(
            `${process.env.VUE_APP_API_URL}/vouchers/refund`,
            this.inputData
          )
          .then(response => {
            if (response.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Generate Voucher Refund Success',
                detail: 'voucher refund has been successfully created',
                life: 3000
              })
              this.getListTransaction()
              this.error_msg.transaction_items[i].qty = ''
            } else {
              this.$toast.add({
                severity: 'error',
                summary: 'Create Voucher Failed',
                detail: 'Sorry, there is a problem during creating the voucher',
                life: 3000
              })
            }
          })
      }
      this.generateDialog = false
      this.allPrice = ''
    },
    qtyChanges(index) {
      let qty = 0

      if (this.dataItem.transaction[index].jml) {
        qty = parseInt(this.dataItem.transaction[index].jml)
      }

      if (index > -1) {
        this.dataItem.transaction[index].jml = qty
      }

      if (
        this.dataItem.transaction[index].jml >
        this.dataItem.transaction[index].qty
      ) {
        this.isError.transaction_items[index].qty = true
        this.error_msg.transaction_items[index].qty = 'Quantity not enough'
      } else {
        this.error_msg.transaction_items[index].qty = ''
        this.dataItem.transaction[index].total_price =
          qty * parseFloat(this.dataItem.transaction[index].price)
        this.calculateTotal()
      }
    },
    calculateTotal() {
      var subtotal = 0
      for (var i = 0; i < this.dataItem.transaction.length; i++) {
        var lineTotal = parseFloat(this.dataItem.transaction[i].total_price)
        if (!isNaN(lineTotal)) {
          subtotal += lineTotal
        }
      }
      if (subtotal != undefined) {
        this.dataItem.transaction.total_price = subtotal.toFixed(2)
      }
      this.allPrice = subtotal
    },
    deleteVoucher(id) {
      this.loading.deleteButton = true
      this.$http
        .delete(`${process.env.VUE_APP_API_URL}/vouchers/${id}`)
        .then(response => {
          this.loading.deleteButton = false
          this.deleteDialog = false
          if (response.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Delete Voucher Success',
              detail: 'Voucher has been successfully deleted',
              life: 3000
            })
            this.getListTransaction()
          } else {
            console.log('gagal delete')
          }
        })
    },
    getListTransaction() {
      this.$http
        .get(
          `${process.env.VUE_APP_API_URL}/vouchers/refund?invoice_number=${this.invoice_number}`
        )
        .then(response => {
          if (response.status === 200) {
            this.dataItem.transaction = response.data.data.transaction_items
            this.vouchers = response.data.data.voucher
            if (this.dataItem.transaction != 0) {
              for (var i = 0; i < this.dataItem.transaction.length; i++) {
                this.dataItem.transaction[i][this.jml] = 0
                this.dataItem.transaction[i][this.total_price] = 0
                this.isError.transaction_items.push({ id: false, qty: false })
                this.error_msg.transaction_items.push({ id: '', qty: '' })
              }
            } else {
              this.$toast.add({
                severity: 'error',
                summary: 'Invalid Invoice',
                detail: 'Please, check the invoice number again',
                life: 3000
              })
            }
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Invalid Invoice',
              detail: 'Please, check the invoice number again',
              life: 3000
            })
            console.log('gagal get')
          }
        })
    }
  }
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
