<template>
  <div class="card">
    <Toast />

    <div class="title">Review Stock Opname</div>

    <div>
      <div class="p-grid">
        <div class="p-col-3">
          <label for="">Status</label>
          <div
            v-if="stock_opname.status == 'START'"
            style="color: green; font-weight: bold"
          >
            {{ stock_opname.status }}
          </div>
          <div
            v-else-if="stock_opname.status == 'END'"
            style="color: red; font-weight: bold"
          >
            {{ stock_opname.status }}
          </div>
          <div v-else style="color: blue; font-weight: bold">
            {{ stock_opname.status }}
          </div>
        </div>
        <div class="p-col-3">
          <label for="">Waktu Mulai</label>
          <div>{{ stock_opname.start | basicDateTime }}</div>
        </div>
        <div class="p-col-3">
          <label for="">Total Kekurangan</label>
          <div>{{ stock_opname.deficiency_total | number }}</div>
        </div>
        <div class="p-col-3">
          <label for="">Total Kelebihan</label>
          <div>{{ stock_opname.excess_total | number }}</div>
        </div>
      </div>

      <div class="p-grid">
        <div class="p-col-3"></div>
        <div class="p-col-3">
          <label for="">Waktu Selesai</label>
          <div>-</div>
        </div>
        <div class="p-col-3">
          <label for="">Nilai Kekurangan</label>
          <div v-if="stock_opname.status == 'START'">
            {{ stock_opname.replacement_price_deficiency | currency }}
          </div>
          <div v-else>
            {{ stock_opname.deficiency_value | currency }}
          </div>
        </div>
        <div class="p-col-3">
          <label for="">Nilai Kelebihan</label>
          <div>{{ stock_opname.excess_value | currency }}</div>
        </div>
      </div>

      <DataTable
        :value="stock_opname_detail"
        :paginator="true"
        class="p-datatable-outlets"
        :rows="25"
        :loading="loading"
        :rowHover="true"
      >
        <template #header>
          <div class="p-grid p-fluid p-mb-2">
            <div class="p-col">
              <div>
                <InputNumber
                  mode="decimal"
                  :useGrouping="false"
                  v-model="filters.cat_code"
                  placeholder="Code Category"
                />
              </div>
            </div>
            <div class="p-col">
              <div>
                <InputText
                  type="text"
                  v-model="filters.cat_name"
                  placeholder="Name Category"
                />
              </div>
            </div>
            <div class="p-col">
              <div>
                <Button
                  label="Cari"
                  class="p-button-info"
                  @click="getReview()"
                  v-shortkey.push="['enter']"
                  @shortkey="getReview()"
                />
              </div>
            </div>
          </div>
        </template>
        <template #empty>Tak ada data</template>

        <Column header="No">
          <template #body="slotProps">
            {{ stock_opname_detail.indexOf(slotProps.data) + 1 }}
          </template>
        </Column>
        <Column header="Gambar">
          <template #body="slotProps">
            <img
              :src="slotProps.data.picture ? slotProps.data.picture : no_image"
              alt="mode_fashion_img"
              style="width: 50px"
            />
          </template>
        </Column>
        <Column header="Kode Barang">
          <template #body="slotProps">
            <span>{{ slotProps.data.item_code }}</span>
          </template>
        </Column>
        <Column header="Nama Barang">
          <template #body="slotProps">
            <span>{{ slotProps.data.item_name }}</span>
          </template>
        </Column>
        <Column header="Stok Barang">
          <template #body="slotProps">
            <span>{{ slotProps.data.stock }}</span>
          </template>
        </Column>
        <Column header="Stok Opname">
          <template #body="slotProps">
            <span>{{ slotProps.data.temporary_stock }}</span>
          </template>
        </Column>
        <Column header="Selisih">
          <template #body="slotProps">
            <span v-if="slotProps.data.type == 'DEFICIENCY'"
              >-{{ slotProps.data.difference }}</span
            >
            <span v-else>{{ slotProps.data.difference }}</span>
          </template>
        </Column>
        <Column header="Harga Jual">
          <template #body="slotProps">
            <span>{{ slotProps.data.price | currency }}</span>
          </template>
        </Column>
      </DataTable>

      <div
        class="p-grid p-fluid p-col-12 p-justify-center p-mt-4"
        v-if="stock_opname.status === 'START'"
      >
        <div class="p-col-12 p-md-6 p-lg-3">
          <Button label="Proses" @click="processed_dialog = true" />
        </div>
      </div>

      <Dialog
        :visible.sync="processed_dialog"
        :style="{ width: '450px' }"
        header="Konfirmasi"
        :modal="true"
      >
        <div class="p-field">
          <label class="p-mr-3" for="">Username</label>
          <InputText
            required
            v-model="form.email"
            placeholder="Username"
            @keyup.prevent="is_error.email = false"
          />
          <small class="p-error" v-if="is_error.email">{{
            error_msg.email
          }}</small>
        </div>
        <div class="p-field">
          <label class="p-mr-3" for="">Password</label>
          <InputText
            required
            type="password"
            v-model="form.password"
            style="margin-left: 0.3rem"
            placeholder="Password"
          />
          <small class="p-error" v-if="is_error.password">{{
            error_msg.password
          }}</small>
        </div>
        <template #footer>
          <Button
            label="Tidak"
            icon="pi pi-times"
            class="p-button-text"
            @click="processed_dialog = false"
          />
          <Button
            label="Ya"
            icon="pi pi-check"
            class="p-button-text"
            @click="approve()"
          />
        </template>
      </Dialog>

      <Dialog
        :visible.sync="finalize_dialog"
        :style="{ width: '500px' }"
        :modal="true"
        :closable="false"
      >
        <div class="confirmation-content text-center" style="font-size: 3rem">
          Silahkan tunggu...
          <ProgressBar
            mode="indeterminate"
            style="height: 0.5rem; margin-top: 2rem"
          />
        </div>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import noImage from '../../assets/images/no-image.png'

export default {
  computed: {
    ...mapState(['user', 'isAuthenticated'])
  },

  watch: {
    user() {
      this.getAuthUser()
    }
  },

  mounted() {
    this.getAuthUser()
  },

  data() {
    return {
      stock_opname_id: 0,
      loading: false,
      no_image: noImage,
      excess_total: '',
      excess_value: '',
      stock_opname: {
        id: '',
        start: '',
        end: '',
        status: '',
        deficiency_total: '',
        deficiency_value: '',
        excess_total: '',
        excess_value: ''
      },

      stock_opname_detail: [],
      processed_dialog: false,
      finalize_dialog: false,
      step: 0,

      executed: false,
      proses: 0,
      bar: 0,
      filters: {
        cat_code: null,
        cat_name: ''
      },
      form: {
        email: '',
        password: ''
      },
      is_error: {
        email: false,
        password: false
      },
      error_msg: {
        email: '',
        password: ''
      },
      isReloading: false
    }
  },

  created() {
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  },

  destroyed() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  },

  methods: {
    getAuthUser() {
      if (this.user && this.user.stock_opname.status == 'START') {
        this.stock_opname_id = this.user.stock_opname.id
        this.getReview()
      }
    },
    handleBeforeUnload(event) {
      if (!this.isReloading) {
        event.preventDefault()
        event.returnValue = '' // Needed for Chrome
      }
    },
    getReview() {
      this.loading = true
      let code = ''
      if (this.filters.cat_code) {
        code = this.filters.cat_code
      }
      this.$http
        .get(
          `${process.env.VUE_APP_API_URL}/pos/stock-opname/${this.stock_opname_id}?cat_code=${code}&cat_name=${this.filters.cat_name}`
        )
        .then(response => {
          if (response.status === 200) {
            this.loading = false
            this.stock_opname = response.data.data.stock_opname
            this.stock_opname_detail = response.data.data.stock_opname_detail
          }
        })
    },

    loopBatch() {
      this.$http
        .post(`${process.env.VUE_APP_API_URL}/pos/stock-opname/finalize`)
        .then(response => {
          if (response.status === 200) {
            this.step = response.data.data.step
            if (this.step < 0) {
              this.finalize_dialog = false
              this.endOpname()
            } else {
              this.loopBatch()
            }
          } else if (response.status === 400) {
            this.finalize_dialog = false
            this.endOpname()
          }
        })
    },

    endOpname() {
      this.$http
        .post(`${process.env.VUE_APP_API_URL}/pos/stock-opname/end`)
        .then(response => {
          if (response.status === 200) {
            if (!this.executed) {
              this.executed = true
              this.$toast.add({
                severity: 'success',
                summary: 'Berhasil memproses data stock opname',
                life: 3000
              })

              setTimeout(() => {
                this.isReloading = true
                this.$router.push({
                  name: 'stock-opname-create'
                })
                window.location.reload()
              }, 800)
              this.getAuthUser()
            }
          } else {
            if (!this.executed) {
              this.$toast.add({
                severity: 'error',
                summary: 'Gagal memproses data stock opname',
                life: 3000
              })
            }
          }
        })
    },

    approve() {
      if (!this.form.email) {
        this.is_error.email = true
        this.error_msg.email = 'Email tidak boleh kosong'
      }

      if (!this.form.password) {
        this.is_error.password = true
        this.error_msg.password = 'Password tidak boleh kosong'
      }

      this.$http
        .put(
          `${process.env.VUE_APP_API_URL}/pos/stock-opname/approve/${this.stock_opname_id}`,
          this.form
        )
        .then(response => {
          if (response.status === 200) {
            this.processed()
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Gagal memproses data stock opname',
              life: 3000
            })
          }
        })
    },

    processed() {
      if (this.stock_opname_detail.length > 0) {
        this.processed_dialog = false
        this.finalize_dialog = true
        this.$http
          .post(`${process.env.VUE_APP_API_URL}/pos/stock-opname/finalize`)
          .then(response => {
            if (response.status === 200) {
              this.step = response.data.data.step
              this.proses = 100 / this.step
              this.loopBatch()
            } else if (response.status === 400) {
              this.finalize_dialog = false
              this.endOpname()
            }
          })
      } else {
        this.processed_dialog = false
        this.$toast.add({
          severity: 'error',
          summary: 'Tidak ada data stock opname',
          life: 3000
        })
      }
    }
  }
}
</script>

<style scoped></style>
