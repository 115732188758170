<template>
  <div>
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  components: {}
}
</script>
