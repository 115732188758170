<template>
  <div class="card">
    <Toast />
    <div class="title">Klaim Hadiah</div>
    <div class="p-grid p-fluid p-formgroup-inline">
      <div class="p-field p-col-4">
        <InputText
          placeholder="Nomor Invoice"
          ref="invoice_number"
          invoice_lama="invoice_number"
          v-model="invoice_number"
          v-shortkey.push="['enter']"
          @shortkey="getAmountTransaction()"
        />
        <div>
          <small class="p-error" v-if="isError.invoice_number">{{
            error_msg.invoice_number
          }}</small>
        </div>
      </div>
      <div class="p-col-2">
        <Button label="Cek Invoice" @click="getAmountTransaction" />
      </div>
    </div>

    <DataTable v-if="detail_invoice != null && amount > 0">
      <template #header>
        <div class="p-grid p-fluid p-mb-2 p-col-12" style="margin: auto">
          <div class="p-col-3">
            <InputText v-model="filter.kode" placeholder="Kode Barang" />
          </div>
          <div class="p-col-2">
            <Button
              label="Cari"
              class="p-button-info"
              @click="getEligableGift()"
            />
          </div>
          <div class="p-col" style="margin-left: 8rem">
            <p style="font-size: 16px; line-height: 0.1rem">
              Nomor invoice : <b>{{ detail_invoice.invoice }}</b>
            </p>
            <p style="font-size: 14px">
              Total belanja : <b>{{ detail_invoice.amount | currency }}</b>
            </p>
          </div>
        </div>
      </template>
    </DataTable>

    <div class="p-grid p-fluid p-col">
      <table class="table table-responsive">
        <thead class="thead-light">
          <tr v-if="detail_invoice != null">
            <th class="text-center">No</th>
            <th class="text-center">Jumlah</th>
            <th class="text-center">Kode barang</th>
            <th class="text-center">Nama barang</th>
            <th class="text-center">Stok</th>
            <th class="text-center">Kode kategori</th>
            <th class="text-center">Range Harga</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(an_item, k) in dataItem.eligable_gift" :key="k">
            <td class="text-center">{{ k + 1 }}</td>
            <td class="text-center">
              <div>
                <InputText
                  v-model="an_item.jml"
                  type="number"
                  style="width: 50pt"
                  :max="an_item.stock"
                  @keyup.prevent="qtyChanges(k)"
                />
              </div>
              <!-- <small class="p-error" v-if="isError.eligable_gift[k].stock">{{
                error_msg.eligable_gift[k].stock
              }}</small> -->
            </td>
            <td class="text-center">{{ an_item.code }}</td>
            <td class="text-center">{{ an_item.name }}</td>
            <td class="text-center">{{ an_item.stock }}</td>
            <td class="text-center">{{ an_item.categoryID }}</td>
            <td class="text-center">{{ an_item.minPrice | currency }}</td>
          </tr>
          <tr v-if="dataItem.eligable_gift.length != 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <b>Total Hadiah<br />Total Qty</b>
            </td>
            <td>
              <b>{{ allPrice | currency }}<br />{{ qty | number }}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="p-grid p-fluid p-justify-center"
      v-if="dataItem.eligable_gift.length != 0"
    >
      <div class="p-col-12 p-md-6 p-lg-3">
        <Button
          :disabled="generateButton"
          @click="klaimGift()"
          label="Klaim"
          style="position: relative"
        >
          <span
            v-if="loading"
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            "
          >
            <i class="pi pi-spin pi-spinner" style="font-size: 12px"></i>
            loading
          </span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      amount: null,
      detail_invoice: null,
      invoice_number: null,
      generateButton: true,
      qty: 0,
      jml: null,
      total_price: 0,
      dataItem: {
        eligable_gift: [],
        cust_id: null
      },

      transaction: {
        cust_id: null,
        invoice: null,
        items: []
      },

      isError: {
        invoice_number: null,
        stock: false
      },
      error_msg: {
        invoice_number: null,
        eligable_gift: []
      },

      filter: {
        eligable_gift: []
      },
      loading: false
    }
  },
  mounted() {
    setTimeout(this.setFocus, 1000)
  },
  methods: {
    setFocus() {
      this.$refs.invoice_number.$el.focus()
    },
    getEligableGift() {
      let code = ''
      if (this.filter.kode) {
        code = this.filter.kode
      }
      this.$http
        .get(
          `${process.env.VUE_APP_API_URL}/items/gift?invoice=${this.invoice_number}&total=${this.detail_invoice.amount}&code=${code}`
        )
        .then(response => {
          if (response.status === 200) {
            this.dataItem.eligable_gift = response.data.data.giftItem
            // add total_price in every array
            this.dataItem.eligable_gift.forEach((item, index) => {
              this.dataItem.eligable_gift[index].total_price = 0
            })
          } else {
            console.log('hadiah kosong')
          }
        })
    },
    getAmountTransaction() {
      if (this.invoice_number != null) {
        this.$http
          .get(
            `${process.env.VUE_APP_API_URL}/invoice?invoice_number=${this.invoice_number}`
          )
          .then(response => {
            if (response.status === 200) {
              this.detail_invoice = response.data.data
              this.amount = response.data.data.amount
              this.dataItem.cust_id = response.data.data.cust_id
              this.isError.invoice_number = false
              this.dataItem.eligable_gift = []
              if (this.amount > 0) {
                this.getEligableGift()
              } else {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Not Eligible',
                  detail: 'Please try another invoice',
                  life: 3000
                })
              }
            }
          })
          .catch(error => {
            this.detail_invoice = null
            this.amount = null
            this.dataItem.cust_id = null
            this.dataItem.eligable_gift = []
            console.log(error)
            this.$toast.add({
              severity: 'error',
              summary: 'Invoice not found',
              detail: 'Please try another invoice',
              life: 3000
            })
          })
      } else {
        this.detail_invoice = null
        this.amount = null
        this.dataItem.cust_id = null
        this.isError.invoice_number = true
        this.error_msg.invoice_number = 'Nomor Invoice tidak boleh kosong'
      }
    },

    qtyChanges(index) {
      let qty = 0
      if (this.dataItem.eligable_gift[index].jml) {
        qty = parseInt(this.dataItem.eligable_gift[index].jml)
      }

      if (index > -1) {
        this.dataItem.eligable_gift[index].jml = qty
      }

      if (
        this.dataItem.eligable_gift[index].jml <=
        this.dataItem.eligable_gift[index].stock
      ) {
        this.dataItem.eligable_gift[index].total_price =
          qty * parseFloat(this.dataItem.eligable_gift[index].minPrice)
        this.calculateTotal()
      }
    },

    calculateTotal() {
      var subtotal = 0,
        qty = 0
      for (var i = 0; i < this.dataItem.eligable_gift.length; i++) {
        var lineTotal = parseFloat(this.dataItem.eligable_gift[i].total_price)
        var qtyTotal = parseFloat(this.dataItem.eligable_gift[i].jml)
        if (!isNaN(lineTotal) && !isNaN(qtyTotal)) {
          subtotal += lineTotal
          qty += qtyTotal
        }
      }
      if (subtotal != undefined && subtotal != undefined) {
        this.dataItem.eligable_gift.total_price = subtotal.toFixed(2)
        this.dataItem.eligable_gift.jml = qty
      }
      this.allPrice = subtotal
      this.qty = qty

      if (this.allPrice == 0 && this.allPrice <= this.amount) {
        this.generateButton = true
      } else if (this.allPrice <= this.amount && this.allprice != 0) {
        this.generateButton = false
      } else {
        this.generateButton = true
        this.$toast.add({
          severity: 'error',
          summary: 'Kelebihan',
          detail: 'Hadiah melebihi total transaksi',
          life: 3000
        })
      }
    },

    klaimGift() {
      this.loading = true
      if (
        this.dataItem.eligable_gift.length === 0 ||
        this.dataItem.eligable_gift.length === null
      ) {
        this.$toast.add({
          severity: 'error',
          summary: 'Cart gift is empty',
          detail: 'Please, select at least one item',
          life: 3000
        })
      } else {
        // penangkapan parameter datanya
        this.transaction.cust_id = this.dataItem.cust_id
        this.transaction.invoice = this.invoice_number
        for (var i = 0; i < this.dataItem.eligable_gift.length; i++) {
          if (this.dataItem.eligable_gift[i].jml != null) {
            this.transaction.items.push({
              id: this.dataItem.eligable_gift[i].id,
              qty: this.dataItem.eligable_gift[i].jml
            })
          }
        }

        this.$http
          .post(
            `${process.env.VUE_APP_API_URL}/transactions/gift`,
            this.transaction
          )
          .then(response => {
            if (response.status === 200) {
              this.openLinkNewTab('receipt', {})
              this.clearGiftClaim()
              this.$router.push({ name: 'pos' })
            } else {
              this.isError.invoice_number = false
              this.$toast.add({
                severity: 'error',
                summary: 'Claim Gift Error',
                detail: 'Sorry, Claim gift failed',
                life: 3000
              })
            }
          })
      }
    },

    clearGiftClaim() {
      this.loading = false
      this.amount = null
      this.detail_invoice = null
      this.invoice_number = null
      this.dataItem = {
        eligable_gift: [],
        cust_id: null
      }

      this.transaction = {
        cust_id: null,
        invoice: null,
        items: []
      }
      this.isError = {
        invoice_number: null
      }
      this.error_msg = {
        invoice_number: null
      }
    }
  }
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
