<template>
  <div class="card">
    <Toast />

    <div class="title">List Stock Opname</div>

    <DataTable
      :value="stock_opname"
      :paginator="true"
      class="p-datatable-outlets"
      :rows="10"
      :rowHover="true"
    >
      <template #empty>Tak ada data</template>
      <template #loading>Loading ...</template>

      <Column header="Start">
        <template #body="slotProps">
          <span>{{ slotProps.data.start }}</span>
        </template>
      </Column>
      <Column header="End">
        <template #body="slotProps">
          <span>{{ slotProps.data.end }}</span>
        </template>
      </Column>
      <Column header="Kekurangan">
        <template #body="slotProps">
          <span>{{ slotProps.data.deficiency_total }}</span>
        </template>
      </Column>
      <Column header="Kelebihan">
        <template #body="slotProps">
          <span>{{ slotProps.data.excess_total }}</span>
        </template>
      </Column>
      <Column header="Status">
        <template #body="slotProps">
          <span
            v-if="slotProps.data.status == 'START'"
            style="font-weight: bold; color: green"
            >{{ slotProps.data.status }}
          </span>
          <span
            v-else-if="slotProps.data.status == 'END'"
            style="font-weight: bold; color: red"
            >{{ slotProps.data.status }}
          </span>
          <span
            v-if="slotProps.data.status == 'PROCESSED'"
            style="font-weight: bold; color: blue"
            >{{ slotProps.data.status }}
          </span>
        </template>
      </Column>
      <Column
        headerStyle="width: 12rem"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <div>
            <router-link
              :to="{
                name: 'stok-opname-detail',
                query: { id: slotProps.data.id }
              }"
            >
              <Button
                type="button"
                icon="pi pi-eye"
                class="p-button-rounded p-mr-2"
              ></Button>
            </router-link>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['user', 'isAuthenticated'])
  },
  watch: {
    user() {
      this.getAuthUser()
    }
  },
  data() {
    return {
      stock_opname_id: 0,
      stock_opname: []
    }
  },
  mounted() {
    this.getAuthUser()
    this.getListStokOpname()
  },
  methods: {
    getAuthUser() {
      if (this.user && this.user.stock_opname) {
        this.stock_opname_id = this.user.stock_opname.id
      }
    },
    getListStokOpname() {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}/pos/stock-opname`)
        .then(response => {
          if (response.status === 200) {
            this.stock_opname = response.data.data.stock_opname
          }
        })
    }
  }
}
</script>

<style scoped></style>
