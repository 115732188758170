<template>
  <div class="">
    <Toast />
    <div class="card">
      <h5>
        <b>Change Password</b>
      </h5>
    </div>

    <div class="card">
      <div class="p-grid p-mb-6">
        <div class="p-col-12">
          <label>Current Password</label>
          <div>
            <InputText
              type="password"
              placeholder="Current Password"
              v-model="oldPassword"
              style="width: 33.3%"
            />
          </div>
        </div>

        <div class="p-col-12">
          <label>New Password</label>
          <div>
            <InputText
              type="password"
              placeholder="New Password"
              v-model="newPassword"
              style="width: 33.3%"
            />
          </div>
          <small class="p-error">{{ msgNewPwd }}</small>
        </div>

        <div class="p-col-12">
          <label>Confirm Password</label>
          <div>
            <InputText
              type="password"
              placeholder="Confirm Password"
              v-model="confirmPassword"
              style="width: 33.3%"
            />
          </div>
          <small class="p-error">{{ messageValidation }}</small>
        </div>
      </div>

      <div class="p-grid p-justify-center">
        <div class="p-col-3">
          <div v-if="checkedNewPwd && checked && oldPassword.length > 0">
            <button
              type="button"
              class="btn btn-primary"
              style="width: 100%"
              @click="saveChanges"
              :disabled="loading.changePassButton"
            >
              Save Changes
              <span v-if="loading.changePassButton">
                <i class="pi pi-spin pi-spinner" style="fontsize: 12px"></i>
              </span>
            </button>
          </div>
          <div v-else>
            <button
              disabled
              class="btn btn-primary"
              type="button"
              style="width: 100%"
              @click="saveChanges"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      msgNewPwd: '',
      checkedNewPwd: true,

      confirmPassword: '',
      messageValidation: '',

      checked: true,
      inputData: {
        password: '',
        new_password: '',
        new_password_confirm: ''
      },
      loading: {
        changePassButton: false
      }
    }
  },
  watch: {
    confirmPassword(value) {
      this.confirmPassword = value
      this.validatePassword(value)
    },
    newPassword(value) {
      this.newPassword = value
      this.validateNewPwd(value)
    }
  },
  methods: {
    saveChanges() {
      this.loading.changePassButton = true
      ;(this.inputData.password = this.oldPassword),
        (this.inputData.new_password = this.newPassword),
        (this.inputData.new_password_confirm = this.confirmPassword),
        this.$http
          .post(
            `${process.env.VUE_APP_API_URL}/auth/change-password`,
            this.inputData
          )
          .then(response => {
            this.loading.changePassButton = false
            if (response.status === 200) {
              console.log(response)
              this.$toast.add({
                severity: 'success',
                summary: 'Change Password Success',
                detail: 'Congrats, your password has changed',
                life: 3000
              })
              this.oldPassword = ''
              this.newPassword = ''
              this.confirmPassword = ''
              this.checkedNewPwd = true
              this.checked = true
              this.inputData = {
                password: '',
                new_password: '',
                new_password_confirm: ''
              }
              this.messageValidation = ''
              this.msgNewPwd = ''
            } else {
              this.$toast.add({
                severity: 'error',
                summary: 'Change Password Failed',
                detail: "Sorry, your password can't change",
                life: 3000
              })
              this.oldPassword = ''
              this.newPassword = ''
              this.confirmPassword = ''
              this.checkedNewPwd = true
              this.checked = true
              this.inputData = {
                password: '',
                new_password: '',
                new_password_confirm: ''
              }
              this.messageValidation = ''
              this.msgNewPwd = ''
            }
          })
    },
    validateNewPwd(value) {
      var regexLowercase = /^(?=.*[a-z]).+$/ // Lowercase character pattern
      var regexUppercase = /^(?=.*[A-Z]).+$/ // Uppercase character pattern
      var regexNumber = /^(?=.*[\d]).+$/ // Special character or number pattern

      this.checkedNewPwd = false
      if (value.length != 0 && value.length < 6) {
        this.msgNewPwd = 'minimal 6 digit'
      } else if (
        !regexLowercase.test(value) ||
        !regexUppercase.test(value) ||
        !regexNumber.test(value)
      ) {
        if (this.newPassword.length > 0) {
          this.msgNewPwd =
            'must contain alphanumeric, lowercase, dan uppercase letter'
        }
      } else if (this.oldPassword == value) {
        this.msgNewPwd = 'please type different input from the old password'
      } else {
        this.msgNewPwd = ''
        this.checkedNewPwd = true
      }
    },
    validatePassword(value) {
      if (this.newPassword != value && value.length > 0) {
        this.messageValidation = 'please type the same input'
        this.checked = false
      } else {
        this.messageValidation = ''
        this.checked = true
      }
    }
  }
}
</script>

<style scoped></style>
