var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.items)?_c('ul',[_vm._l((_vm.items),function(item,i){return [(_vm.visible(item) && !item.separator)?_c('li',{key:i,class:[
        {
          'active-menuitem': _vm.activeIndex === i && !item.to && !item.disabled
        }
      ],attrs:{"role":"none"}},[(item.items && _vm.root === true)?_c('div',{staticClass:"arrow"}):_vm._e(),(item.to)?_c('router-link',{class:[
          item.class,
          { 'active-route': _vm.activeIndex === i, 'p-disabled': item.disabled }
        ],style:(item.style),attrs:{"to":item.to,"target":item.target,"exact":"","role":"menuitem"},nativeOn:{"click":function($event){return _vm.onMenuItemClick($event, item, i)}}},[_c('i',{class:item.icon}),_c('span',[_vm._v(_vm._s(item.label))]),(item.items)?_c('i',{staticClass:"pi pi-fw pi-angle-down menuitem-toggle-icon"}):_vm._e(),(item.badge)?_c('span',{staticClass:"menuitem-badge"},[_vm._v(_vm._s(item.badge))]):_vm._e()]):_vm._e(),(!item.to)?_c('a',{class:[item.class, { 'p-disabled': item.disabled }],style:(item.style),attrs:{"href":item.url || '#',"target":item.target,"role":"menuitem"},on:{"click":function($event){return _vm.onMenuItemClick($event, item, i)}}},[_c('i',{class:item.icon}),_c('span',[_vm._v(_vm._s(item.label))]),(item.items)?_c('i',{staticClass:"pi pi-fw pi-angle-down menuitem-toggle-icon"}):_vm._e(),(item.badge)?_c('span',{staticClass:"menuitem-badge"},[_vm._v(_vm._s(item.badge))]):_vm._e()]):_vm._e(),_c('transition',{attrs:{"name":"layout-submenu-wrapper"}},[_c('AppSubmenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeIndex === i),expression:"activeIndex === i"}],attrs:{"items":_vm.visible(item) && item.items},on:{"menuitem-click":function($event){return _vm.$emit('menuitem-click', $event)}}})],1)],1):_vm._e(),(_vm.visible(item) && item.separator)?_c('li',{key:'separator' + i,staticClass:"p-menu-separator",style:(item.style),attrs:{"role":"separator"}}):_vm._e()]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }