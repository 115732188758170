<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />

    <transition name="layout-sidebar">
      <div
        :class="sidebarClass"
        @click="onSidebarClick"
        v-show="isSidebarVisible()"
      >
        <div class="layout-logo">
          <router-link to="/">
            <img alt="Logo" :src="logo" class="" />
          </router-link>
        </div>

        <AppProfile />
        <AppMenu
          :model="menuCashier"
          @menuitem-click="onMenuItemClick"
          v-if="role == 'CASHIER'"
        />
        <AppMenu
          :model="menuStockOpname"
          @menuitem-click="onMenuItemClick"
          v-if="role == 'STOCK_OPNAME'"
        />
      </div>
    </transition>

    <div class="layout-main">
      <router-view />
    </div>

    <AppFooter />
  </div>
</template>

<script>
import AppTopBar from '../AppTopbar.vue'
import AppProfile from '../AppProfile.vue'
import AppMenu from '../AppMenu.vue'
import AppFooter from '../AppFooter.vue'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      layoutMode: 'static',
      layoutColorMode: 'dark',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menuCashier: [
        { label: 'POS', icon: 'pi pi-home', to: '/pos' },
        { label: 'Ringkasan', icon: 'pi pi-chart-bar', to: '/summary' },
        { label: 'Refund', icon: 'pi pi-dollar', to: '/refund' },
        { label: 'Hadiah', icon: 'pi pi-heart', to: '/gift' }
      ],
      menuStockOpname: [
        {
          label: 'Stock Opname',
          icon: 'pi pi-briefcase',
          items: [
            {
              label: 'Buat',
              icon: 'pi pi-plus',
              to: '/stock-opname/create'
            },
            {
              label: 'Review',
              icon: 'pi pi-spinner',
              to: '/stock-opname/review'
            },
            {
              label: 'List',
              icon: 'pi pi-list',
              to: '/stock-opname/list'
            }
          ]
        },
        {
          label: 'Katalog',
          icon: 'pi pi-fw pi-shopping-cart',
          items: [
            {
              label: 'Daftar',
              icon: 'pi pi-fw pi-list',
              to: '/catalog/list'
            }
          ]
        }
      ],
      role: ''
    }
  },
  watch: {
    user() {
      this.getAuthUser()
    },
    $route() {
      this.menuActive = false
      this.$toast.removeAllGroups()
      this.getAuthUser()
    }
  },
  mounted() {
    this.getAuthUser()
  },
  methods: {
    getAuthUser() {
      if (this.user) {
        this.role = this.user.role
      }
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }

      this.menuClick = false
    },
    onMenuToggle() {
      this.menuClick = true

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true
          }

          this.overlayMenuActive = !this.overlayMenuActive
          this.mobileMenuActive = false
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive
      }

      event.preventDefault()
    },
    onSidebarClick() {
      this.menuClick = true
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className)
      else element.className += ' ' + className
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className)
      else
        element.className = element.className.replace(
          new RegExp(
            '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
            'gi'
          ),
          ' '
        )
    },
    isDesktop() {
      return window.innerWidth > 1024
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static') return !this.staticMenuInactive
        else if (this.layoutMode === 'overlay') return this.overlayMenuActive
        else return true
      } else {
        return true
      }
    }
  },
  computed: {
    ...mapState(['user', 'isAuthenticated']),
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive':
            this.staticMenuInactive && this.layoutMode === 'static',
          'layout-overlay-sidebar-active':
            this.overlayMenuActive && this.layoutMode === 'overlay',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$appState.inputStyle === 'filled'
        }
      ]
    },
    sidebarClass() {
      return [
        'layout-sidebar',
        {
          'layout-sidebar-dark': this.layoutColorMode === 'dark',
          'layout-sidebar-light': this.layoutColorMode === 'light'
        }
      ]
    },
    logo() {
      return this.layoutColorMode === 'dark'
        ? require('@/assets/images/logo-white-transparent.png')
        : require('@/assets/images/logo.svg')
    }
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden')
    else this.removeClass(document.body, 'body-overflow-hidden')
  },
  components: {
    AppTopBar: AppTopBar,
    AppProfile: AppProfile,
    AppMenu: AppMenu,
    AppFooter: AppFooter
  }
}
</script>

<style lang="scss">
@import '../App.scss';

.layout-logo a img {
  height: 60px;
}
</style>
