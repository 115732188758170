import Vue from 'vue'
import Vuex from 'vuex'
import vueAuthInstance from '../service/auth.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    isAuthenticated: vueAuthInstance.isAuthenticated(),
    user: {},
    cart: ''
  },

  getters: {
    isAuthenticated() {
      return vueAuthInstance.isAuthenticated()
    },
    user(state) {
      return state.user
    },
    cart(state) {
      return state.cart
    }
  },

  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated
    },
    user(state, payload) {
      state.user = payload
    },
    setCart(state, payload) {
      localStorage.setItem('cart', payload)
      state.cart = payload
    },
    initCart(state) {
      if (localStorage.getItem('cart')) {
        state.cart = localStorage.getItem('cart')
      }
    }
  },

  actions: {
    login(context, payload) {
      payload = payload || {}
      return vueAuthInstance
        .login(payload.user, payload.requestOptions)
        .then(function (response) {
          if (response.status === 200) {
            context.commit('isAuthenticated', {
              isAuthenticated: vueAuthInstance.isAuthenticated()
            })
            context.commit('user', response.data.data.user_info)
            return response
          }
          return response
        })
        .catch(error => {
          return error.response
        })
    },

    logout(context, payload) {
      payload = payload || {}
      return vueAuthInstance
        .logout(payload.requestOptions)
        .then(function (response) {
          console.log(response)
          context.commit('isAuthenticated', {
            isAuthenticated: vueAuthInstance.isAuthenticated()
          })
          context.commit('user', {})
          localStorage.clear()
        })
        .catch(error => {
          console.log(error)
          context.commit('isAuthenticated', false)
          context.commit('user', {})
          localStorage.clear()
        })
    },

    authenticate(context, payload) {
      payload = payload || {}
      return vueAuthInstance
        .authenticate(
          payload.provider,
          payload.userData,
          payload.requestOptions
        )
        .then(
          response => {
            console.log(response)
            context.commit('isAuthenticated', {
              isAuthenticated: vueAuthInstance.isAuthenticated()
            })
          },
          response => {
            console.log(response)
          }
        )
    },

    setUser(context, payload) {
      console.log(payload)
      payload = payload || {}
      if (context.getters.isAuthenticated) {
        return Vue.axios
          .get(process.env.VUE_APP_API_URL + '/auth/user-info')
          .then(response => {
            if (response.status == 200) {
              context.commit('user', response.data.data.user_info)
              return response
            }
            return response
          })
      } else {
        context.commit('user', {})
      }
    }
  }
})

export default store
