<template>
  <div class="card" v-if="summary">
    <Toast />
    <div class="title">Ringkasan</div>
    <div class="p-grid">
      <div class="p-col">
        <label>Kasir</label>
        <div>
          {{ summary.cashier.name }}
        </div>
      </div>

      <div class="p-col">
        <label>Waktu Mulai</label>
        <div>{{ moment(summary.shift.opened_at).format('DD-MM-YYYY') }}</div>
      </div>
      <div class="p-col">
        <label>Cash Awal</label>
        <div>{{ summary.shift.opening_balance | currency }}</div>
      </div>
      <div class="p-col">
        <label>Tanggal Audit</label>
        <div>{{ moment(summary.shift.audit_date).format('DD-MM-YYYY') }}</div>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col">
        <label>Status</label>
        <div>{{ summary.shift.status }}</div>
      </div>
      <div class="p-col">
        <label>Waktu Selesai</label>
        <div>
          {{
            summary.shift.closed_at
              ? moment(summary.shift.closed_at).format('DD-MM-YYYY')
              : '-'
          }}
        </div>
      </div>
      <div class="p-col">
        <label>Cash Akhir</label>
        <div>{{ summary.shift.closing_balance | currency }}</div>
      </div>
      <div class="p-col"></div>
    </div>

    <div class="p-grid p-mt-4">
      <div class="p-col-7">
        <label>Transaksi</label>
        <DataTable
          :value="summary.transaction_summary"
          :paginator="true"
          class="p-datatable-gridlines p-datatable-sm"
          :rows="10"
        >
          <template #empty>Tak ada data</template>
          <template #loading>Loading ...</template>

          <Column header="No" headerStyle="width: 3em">
            <template #body="slotProps">
              {{ summary.transaction_summary.indexOf(slotProps.data) + 1 }}
            </template>
          </Column>
          <Column header="Kode Kategori">
            <template #body="slotProps">
              <span>{{ slotProps.data.category_code }}</span>
            </template>
          </Column>
          <Column header="Nama Kategori">
            <template #body="slotProps">{{
              slotProps.data.category_name
            }}</template>
            <template #footer>Total</template>
          </Column>
          <Column header="Terjual">
            <template #body="slotProps">{{
              slotProps.data.total_sold
            }}</template>
            <template #footer>{{ totalQty | number }}</template>
          </Column>
        </DataTable>
      </div>

      <div class="p-col-5">
        <div class="d-block">
          <label>Pembayaran</label>
          <DataTable
            :value="summary.payment_summary"
            :paginator="false"
            class="p-datatable-gridlines p-datatable-sm"
            :rows="10"
          >
            <template #empty>Tak ada data</template>
            <template #loading>Loading ...</template>

            <Column
              header="No"
              headerStyle="width: 3em"
              headerClass="p-text-center"
              bodyClass="p-text-center"
            >
              <template #body="slotProps">
                {{ summary.payment_summary.indexOf(slotProps.data) + 1 }}
              </template>
            </Column>
            <Column
              header="Metode"
              footerClass="p-text-right"
              headerClass="p-text-center"
            >
              <template #body="slotProps">
                <span> {{ slotProps.data.payment_method }} </span>
              </template>
              <template #footer> Total </template>
            </Column>
            <Column
              header="Jumlah"
              bodyClass="p-text-right"
              footerClass="p-text-right"
              headerClass="p-text-center"
            >
              <template #body="slotProps">
                <span>{{ slotProps.data.total_amount | currency }}</span>
              </template>
              <template #footer>
                <span> {{ totalPayment | currency }} </span>
              </template>
            </Column>
          </DataTable>
        </div>

        <label style="margin-top: 1rem">Non Pembayaran</label>
        <DataTable
          :value="summary.infaq_and_disc"
          :paginator="false"
          class="p-datatable-gridlines p-datatable-sm"
          :rows="10"
        >
          <template #empty>Tak ada data</template>
          <template #loading>Loading ...</template>

          <Column
            header="No"
            headerStyle="width: 3em"
            headerClass="p-text-center"
            bodyClass="p-text-center"
          >
            <template #body="slotProps">
              {{ summary.infaq_and_disc.indexOf(slotProps.data) + 1 }}
            </template>
          </Column>
          <Column
            header="Metode"
            footerClass="p-text-right"
            headerClass="p-text-center"
          >
            <template #body="slotProps">
              <span> {{ slotProps.data.payment_method }} </span>
            </template>
            <template #footer> Total </template>
          </Column>
          <Column
            header="Jumlah"
            bodyClass="p-text-right"
            footerClass="p-text-right"
            headerClass="p-text-center"
          >
            <template #body="slotProps">
              <span>{{ slotProps.data.total_amount | currency }}</span>
            </template>
            <template #footer>
              <span> {{ totalSummaryNonPayment | currency }} </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <div class="p-grid p-mt-3" v-if="is_open_shift">
      <div class="p-col-12">
        <label>Jumlah Cash Akhir (Rp)</label>
        <div>
          <InputNumber
            v-model="closing_balance"
            mode="decimal"
            locale="de-DE"
            placeholder="0"
          />
        </div>
      </div>
      <div class="p-col-12">
        <Checkbox :binary="true" v-model="confirm" />
        <label style="padding-left: 5px; margin: 0px"
          >Saya telah memastikan semua informasi di atas benar</label
        >
      </div>
    </div>

    <div class="p-grid p-fluid p-justify-center p-mt-5" v-if="is_open_shift">
      <div class="p-col-12 p-md-6 p-lg-3">
        <!-- <Button
          label="Konfirmasi"
          class="p-button-primary"
          @click="closeShift()"
          :disabled="!confirm || closing_balance <= 0 || loading.closing"
        /> -->
        <Button
          label="Konfirmasi"
          class="p-button-primary"
          @click="closeShift()"
          :disabled="!confirm || closing_balance <= 0 || loading.closing"
        >
          <span v-if="loading_confirmation">
            <i class="pi pi-spin pi-spinner" style="fontsize: 12px"></i>
          </span>
        </Button>
      </div>
    </div>
    <div class="p-grid p-fluid p-justify-center p-mt-5" v-else>
      <div class="p-col-12 p-md-6 p-lg-3">
        <Button label="Cetak" class="p-button-outlined" @click="printPage()" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

var moment = require('moment')

export default {
  computed: {
    totalPayment() {
      let total = 0
      this.summary.payment_summary.forEach(row => {
        total += parseFloat(row.total_amount)
      })
      return total
    },
    // to get total infaq and diskon
    totalSummaryNonPayment() {
      let total = 0
      this.summary.infaq_and_disc.forEach(row => {
        total += parseFloat(row.total_amount)
      })
      return total
    },
    totalQty() {
      let total = 0
      this.summary.transaction_summary.forEach(row => {
        total += parseInt(row.total_sold)
      })
      return total
    },
    ...mapState(['user', 'isAuthenticated'])
  },
  watch: {
    user() {
      this.getAuthUser()
    }
  },
  data() {
    return {
      moment: moment,
      loading_confirmation: false,
      loading: {
        summary: false,
        closing: false
      },
      summary: null,
      closing_balance: null,
      confirm: false,
      is_open_shift: false
    }
  },
  mounted() {
    this.getAuthUser()
    this.getSummary()
  },
  methods: {
    printPage() {
      this.$router.push({
        name: 'print-summary',
        params: {
          summary: this.summary
        }
      })
    },
    getSummary() {
      this.loading.summary = true
      this.$http
        .get(`${process.env.VUE_APP_API_URL}/shifts/summary`)
        .then(response => {
          this.loading.summary = false
          if (response.status === 200) {
            this.summary = response.data.data
          }
        })
    },
    getAuthUser() {
      if (this.user && this.user.shift && this.user.shift.status == 'OPEN') {
        this.is_open_shift = true
      } else {
        this.is_open_shift = false
      }
    },
    closeShift() {
      this.loading.closing = true
      this.loading_confirmation = true
      this.$http
        .post(`${process.env.VUE_APP_API_URL}/shifts/close`, {
          closing_balance: this.closing_balance.toString()
        })
        .then(response => {
          this.loading.closing = false
          if (response.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Berhasil mengakhiri shift',
              life: 3000
            })

            this.$store
              .dispatch('setUser', { requestOptions: { method: 'GET' } })
              .then(() => {
                this.getAuthUser()
              })
            this.loading_confirmation = false
            this.getSummary()
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Gagal mengakhiri shift',
              life: 3000
            })
            this.loading_confirmation = false
          }
        })
    }
  }
}
</script>

<style scoped lang="scss"></style>
