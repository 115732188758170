<!-- eslint-disable prettier/prettier -->
<template>
  <div style="font-family: Monospace">
    <div v-if="loading">
      <p>Loading receipt...</p>
    </div>
    <div v-else class="receipt-detail">
      <Toast />
      <div>
        <p class="text-center">
          <span style="text-transform: uppercase">
            {{ items.outlet.name }} {{ items.outlet.code }}
          </span>
        </p>
        <hr class="line2" />
        <table class="table table-borderless">
          <th></th>
          <tr v-if="invoice.uniqueCode">
            <td>NOMOR LAMA</td>
            <td>: {{ invoice.uniqueCode }}</td>
          </tr>
          <tr v-if="invoice.old_invoice">
            <td>NOMOR LAMA</td>
            <td>: {{ invoice.old_invoice }}</td>
          </tr>
          <tr>
            <td>NOMOR</td>
            <td>: {{ invoice.number }}</td>
          </tr>
          <tr>
            <td>TANGGAL</td>
            <td>
              : {{ moment(items.invoice.date).format('DD-MM-YYYY HH:mm:ss') }}
            </td>
          </tr>
        </table>
      </div>

      <div>
        <p class="alncenter p-m-2">DETAIL TRANSAKSI</p>
        <table
          class="table table-borderless"
          v-for="(an_item, k) in allTransactions"
          :key="k"
        >
        <th></th>
          <tr>
            <td>{{ an_item.item_code }}</td>
            <td>{{ an_item.item_name }}</td>
          </tr>
          <tr>
            <td colspan="2" class="alnleft">
              {{ an_item.qty }} x {{ an_item.init_price | number }}
            </td>
            <td>=</td>
            <td class="alnright">{{ (an_item.qty * an_item.init_price) | number }}</td>
          </tr>
          <tr v-if="an_item.special_discount > 0">
            <td colspan="2" class="alnleft" style="padding-left:25px">
              Special Disc
            </td>
            <td>=</td>
            <td class="alnright"> -{{
                (an_item.special_discount - an_item.discount) * an_item.qty | number
              }}</td>
          </tr>
          <tr v-if="an_item.discount > 0">
            <td colspan="2" class="alnleft" style="padding-left:25px">
              Disc
              {{ (an_item.discount / an_item.init_price) * 100 | number }}%
            </td>
            <td>=</td>
            <td class="alnright">-{{ an_item.discount * an_item.qty | number }}</td>
          </tr>
        </table>
        <table class="table table-borderless p-m-2">
          <th></th>
          <tr>
            TOTAL QTY:
            {{
              totalQty
            }}
            PCS
          </tr>
        </table>

        <hr class="line5" />
        <table class="table table-borderless" style="width: 100%">
          <th></th>
          <tr>
            <td>SUBTOTAL</td>
            <td colspan="4"></td>
            <td></td>
            <td>=</td>
            <td></td>
            <td class="alnright">
              {{ (subTotalPrice) | number }}
            </td>
          </tr>
          <tr v-if="diskonItemTotal > 0">
            <td>DISKON ITEM</td>
            <td colspan="4"></td>
            <td></td>
            <td>=</td>
            <td></td>
            <td class="alnright">-{{ diskonItemTotal | number }}</td>
          </tr>
          <tr v-if="discountTransaction > 0">
            <td>DISKON MEMBER {{ Math.ceil((discountTransaction/subTotalPrice) * 100) | number }}%</td>
            <td colspan="4"></td>
            <td></td>
            <td>=</td>
            <td></td>
            <td class="alnright">-{{ discountTransaction | number }}</td>
          </tr>
          <tr v-if="specialDiscount > 0">
            <td>SPECIAL DISC</td>
            <td colspan="4"></td>
            <td></td>
            <td>=</td>
            <td></td>
            <td class="alnright">-{{ specialDiscount | number }}</td>
          </tr>
          <tr v-if="vouchers[0]">
            <td>{{ vouchers[0].payment_method }}</td>
            <td colspan="4"></td>
            <td></td>
            <td>=</td>
            <td></td>
            <td class="alnright">-{{ voucherPaymentMethod | number }}</td>
          </tr>
          </table>
          <hr class="line5" />
          <table class="table table-borderless" style="width: 100%">
            <th></th>
            <tr>
              <td>GRAND TOTAL</td>
              <td colspan="4"></td>
              <td></td>
              <td>=</td>
              <td></td>
              <td class="alnright">{{ (subTotalPrice - discountTransaction - diskonItemTotal - voucherPaymentMethod) | number }}</td>
            </tr>
            <tr v-for="item in payments" :key="item.payment_method">
              <td>{{ item.payment_method }}</td>
              <td colspan="4"></td>
              <td></td>
              <td>=</td>
              <td></td>
              <td class="alnright">{{ item.total_amount | number }}</td>
            </tr>
            <tr v-if="infaq > 0">
              <td>INFAQ</td>
              <td colspan="4"></td>
              <td></td>
              <td>=</td>
              <td></td>
              <td class="alnright">-{{ infaq | number }}</td>
            </tr>
            <tr v-if="round_up > 0">
              <td>PEMBULATAN</td>
              <td colspan="4"></td>
              <td></td>
              <td>=</td>
              <td></td>
              <td class="alnright">-{{ round_up | number }}</td>
            </tr>
            <tr>
              <td>KEMBALIAN</td>
              <td colspan="4"></td>
              <td></td>
              <td>=</td>
              <td></td>
              <td class="alnright">
                {{ kembalian | number }}
              </td>
            </tr>
          </table>
        <hr class="line5" />
        <table class="table table-borderless">
          <th></th>
          <p style="margin-bottom: 0.5rem">
            KASIR : {{ cashier }} <br />
            <span v-if="items.sales_person.name.length > 0"
              >Pramuniaga : {{ pramuniaga }}</span
            >
          </p>
          <p
            v-if="totalAllDiscount + specialDiscount > 0"
          >
            SELAMAT ANDA BERHEMAT SEBESAR
          </p>
          <p style="margin-bottom: 0.5rem; margin-top: -0.5rem" v-if="totalAllDiscount + specialDiscount > 0">
            {{ (voucherPaymentMethod + diskonItemTotal + discountTransaction + specialDiscount + round_up) | currency }}
          </p>
        </table>
        <hr
          class="line5"
          style="margin-top: 3.8rem"
          v-if="gift_eligable != null && gift_eligable.point != 0"
        />
        <table
          class="table table-borderless"
          v-if="gift_eligable != null && gift_eligable.point != 0"
        >
        <th></th>
          <div style="margin-bottom: 0.5rem">
            <p style="font-size: 20px; margin-left: 9rem"><b>SELAMAT..</b></p>
            <p style="margin-top: -1.5rem">
              <br />
              <b style="font-size: 18px">ANDA MENDAPATKAN HADIAH BELANJA</b>
              <br />
              <b style="font-size: 18px">POINT {{ gift_eligable.point }}</b>
              <br />
              <barcode
                v-model="invoice.number"
                :options="options"
                :displayValue="false"
              ></barcode>
            </p>
            <div style="margin-top: -1rem">
              <p>Silahkan tukarkan kupon ke stand</p>
              <p>hadiah yang telah disediakan..</p>
            </div>
          </div>
        </table>
      </div>
      <div style="display: flex; justify-content: center">
        ----------------TERIMAKASIH----------------
      </div>

      <Dialog
        :visible.sync="confirmDialog"
        :style="{ width: '450px' }"
        header="Pemberitahuan"
        :modal="true"
        :closable="false"
      >
        <div class="confirmation-content">
          <i class="pi pi-print p-mr-3" style="font-size: 2rem" />
          <span> Print... </span>
        </div>
        <template #footer>
          <Button
            label="Ok"
            icon="pi pi-check"
            class="p-button-text"
            @click="closeDialog()"
            v-shortkey.push="['enter']"
            @shortkey="closeDialog()"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import VueNativeSock from 'vue-native-websocket'
import Vue from 'vue'

let moment = require('moment')
export default {
  data() {
    return {
      moment: moment,
      loading: true,
      products: null,
      expandedRows: [],
      confirmDialog: false,
      items: {},
      allTransactions: [],
      payments: [],
      vouchers: [],
      payments2: [],
      totalQty: 0,
      subTotalPrice: 0,
      totalAllDiscount: 0,
      diskonItemTotal: 0,
      totDiscount: 0,
      specialDiscount: 0,
      discountTransaction: 0,
      totalPaymentAmount: 0,
      voucherPaymentMethod: 0,
      cashPaymentMethod: 0,
      edcPaymentMethod: 0,
      infaq: 0,
      round_up: 0,
      change: 0,
      kembalian: 0,
      invoice: {},
      cashier: null,
      pramuniaga: null,
      gift_eligable: null,
      receiptAPI: null
    }
  },
  mounted() {
    this.getLastReceipt()
    if (process.env.VUE_APP_ENABLE_PRINTER) {
      this.initWS()
    }
  },
  computed: {
    options() {
      return {
        height: 75,
        textAlign: 'left',
        text: ' '
      }
    }
  },
  methods: {
    initWS() {
      //enable websocket
      console.info('Enable websocket printer')
      Vue.use(VueNativeSock, process.env.VUE_APP_PRINTER_URL, {
        reconnection: true, // (Boolean) whether to reconnect automatically (false)
        reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
        reconnectionDelay: 3000 // (Number) how long to initially wait before attempting a new (1000)
      })
    },

    getLastReceipt() {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}/pos/transactions/last-receipt`)
        .then(response => {
          this.loading = false
          if (response.status === 200) {
            this.items = response.data.data
            this.allTransactions = this.items.transaction_items
            const discountTransaction = this.items.transaction.discount
            this.discountTransaction = parseFloat(discountTransaction)
            this.infaq = this.items.transaction.infaq
            this.round_up = parseInt(this.items.transaction.round_up)
            this.payments = this.items.payment
            this.vouchers = this.items.voucher
            this.invoice = this.items.invoice
            let nominalCash = this.payments[0].total_amount
            let nominalEdc = this.payments[0].total_amount

            if (this.invoice.uniqueCode == '') {
              this.getGiftEligable()

              this.totalQty = 0
              this.subTotalPrice = 0

              //encoded cashier name & pramuniaga
              let buff = Buffer.from(this.items.cashier.name)
              let stringToBase64 = buff.toString('base64')
              let cutname = stringToBase64.substr(stringToBase64.length - 7, 7)
              this.cashier = cutname

              let buf = Buffer.from(this.items.sales_person.name)
              let stringBase64 = buf.toString('base64')
              let cutnama = stringBase64.substr(stringBase64.length - 7, 7)
              this.pramuniaga = cutnama
              //end cashier name & pramuniaga

              // calculate transaction item
              for (let t of this.allTransactions) {
                this.totalQty += t.qty

                let subTotalTemp = t.qty * t.init_price
                this.subTotalPrice += subTotalTemp

                let s_discount =
                  parseFloat(t.qty) * parseFloat(t.special_discount)
                this.specialDiscount += parseFloat(s_discount)

                let diskonItem = parseFloat(t.discount) * parseFloat(t.qty)
                this.diskonItemTotal += parseFloat(diskonItem)

                let sumDiscount = parseFloat(t.qty) * parseFloat(t.discount)
                this.totalAllDiscount += parseFloat(sumDiscount)
              }
              // end calculate transaction item

              // calculate payment
              this.totalPaymentAmount = 0
              for (let p of this.payments) {
                this.totalPaymentAmount += parseFloat(p.total_amount)
                this.payments2.push(p)
              }
              //start payment method
              let kembalianCash =
                this.totalPaymentAmount -
                (this.subTotalPrice -
                  this.totalAllDiscount -
                  this.specialDiscount -
                  this.discountTransaction) -
                this.infaq +
                parseFloat(this.round_up)

              if (this.payments[0].is_cash > 0) {
                this.cashPaymentMethod = parseFloat(nominalCash)
                this.kembalian = kembalianCash
              } else if (this.payments[0].is_cash == 0) {
                this.edcPaymentMethod = parseFloat(nominalEdc)
                this.kembalian = 0
              } else {
                console.info('no payment')
              }

              // check use voucher or not
              if (
                this.vouchers.length > 0 &&
                (this.vouchers[0].payment_method == 'VOUCHER SHOPPING' ||
                  this.vouchers[0].payment_method == 'VOUCHER REFUND')
              ) {
                if (this.payments[0].is_cash > 0) {
                  this.cashPaymentMethod = parseFloat(nominalCash)
                  let nominalVoucher1 = this.vouchers[0].total_amount
                  this.voucherPaymentMethod = parseFloat(nominalVoucher1)
                  this.kembalian = kembalianCash + this.voucherPaymentMethod
                } else if (this.payments[0].is_cash == 0) {
                  this.edcBNIPaymentMethod = parseFloat(nominalEdc)
                  let nominalVoucher2 = this.vouchers[0].total_amount
                  this.voucherPaymentMethod = parseFloat(nominalVoucher2)
                  this.kembalian = 0
                }
              } else {
                console.info('no voucher')
              }
            } else {
              for (let t of this.allTransactions) {
                this.totalQty += t.qty
              }
            }
            //end

            this.change =
              parseFloat(this.subTotalPrice) -
              (parseFloat(this.sumDiscount) +
                parseFloat(this.infaq) +
                this.totalPaymentAmount) +
              parseFloat(this.round_up)

            if (!process.env.VUE_APP_ENABLE_PRINTER) {
              setTimeout(function () {
                window.print()
              }, 500)
              setTimeout(function () {
                window.close()
              }, 500)
            } else {
              //* uncomment this for print from api
              this.printReceiptDirecltyFromApi()
              setTimeout(() => {
                this.confirmDialog = true
              }, 1000)
              // //* uncomment this for print from front end
              // this.printReceiptDireclty()
            }
          } else {
            console.error('gagal get last-receipt')
          }
        })
    },

    getGiftEligable() {
      this.$http
        .get(
          `${process.env.VUE_APP_API_URL}/invoice?invoice_number=${this.invoice.number}`
        )
        .then(response => {
          if (response.status === 200) {
            this.gift_eligable = response.data.data
          } else {
            this.gift_eligable = null
          }
        })
    },

    closeDialog() {
      this.confirmDialog = false
      window.close()
    },

    printReceiptDirecltyFromApi() {
      this.$http
        .get(
          `${process.env.VUE_APP_API_URL}/pos/transactions/last-receipt/endpoint/json`
          // `${process.env.VUE_APP_API_URL}/pos/transactions/last-receipt/endpoint`
        )
        .then(response => {
          if (response.status === 200) {
            this.receiptAPI = JSON.stringify(response.data.data.Receipt)
            // this.receiptAPI = response.data.data.Receipt
            this.$socket.send(this.receiptAPI)
          } else {
            console.error('gagal get history-receipt')
          }
        })
    },

    printReceiptDireclty() {
      let len = process.env.VUE_APP_PRINTER_LENGTH
      let temp = ''
      let i = 0
      let line,
        line2 = ''
      // title outlet  name
      let receipt = this.items.outlet.name.padEnd(len, ' ') + '#'
      receipt += this.items.outlet.address.padEnd(len, ' ') + '#'

      // the line receipt
      line = ''
      for (i; i < len; i++) {
        line += '='
      }
      receipt += line + '#'

      // invoice number
      if (this.invoice.uniqueCode) {
        receipt += 'Nomor Lama : ' + this.invoice.uniqueCode + '#'
      }
      receipt += 'Nomor      : ' + this.invoice.number + '#'

      // invoice date
      receipt +=
        'Tanggal    : ' +
        this.moment(this.items.invoice.date).format('DD-MM-YYYY HH:mm:ss')

      // title detail transaksi
      receipt += '#    DETAIL TRANSAKSI    #'

      // loop item in that transaction
      this.allTransactions.forEach(item => {
        receipt += item.item_code + ' ' + item.item_name + ' #'
        temp =
          item.qty +
          '@' +
          this.$options.filters.number(item.init_price) +
          ' = ' +
          this.$options.filters.number(item.qty * item.init_price) +
          '#'
        receipt += temp.padStart(len, ' ')

        // spceial diskon dan diskon item
        if (item.special_discount > 0) {
          receipt +=
            'special disc = ' +
            this.$options.filters.number(this.calculateSpecialDisc(item)) +
            '#'
        }
        if (item.discount > 0) {
          receipt +=
            'disc = ' +
            this.$options.filters.number(this.calculateDisc(item)) +
            '%' +
            '#'
        }
      })
      // total qty and line receipt
      receipt += '#Total Qty  : ' + this.totalQty + ' pcs#'
      for (i = 0; i < len; i++) {
        line2 += '-'
      }
      receipt += line2 + '#'

      // total price
      temp = this.$options.filters.number(
        this.subTotalPrice - this.specialDiscount
      )
      receipt += 'Total Belanja' + temp.padStart(len - 13, ' ') + '#'

      // disc member
      if (this.discountTransaction > 0) {
        temp =
          '(' + this.$options.filters.number(this.discountTransaction) + ')'
        receipt += 'Disc Member' + temp.padStart(len - 11, ' ') + '#'
      }

      // kind voucher use
      if (this.vouchers.length > 0) {
        temp =
          '(' + this.$options.filters.number(this.voucherPaymentMethod) + ')'
        receipt +=
          this.vouchers[0].payment_method + temp.padStart(len - 16, ' ') + '#'
      }

      // payment method(tunai, edc bni, edc bri)
      if (this.payments[0].payment_method == 'CASH') {
        temp = this.$options.filters.number(this.cashPaymentMethod)
        receipt += 'Tunai' + temp.padStart(len - 5, ' ') + '#'
      } else if (this.payments[0].payment_method == 'EDC BNI') {
        temp = this.$options.filters.number(this.edcBNIPaymentMethod)
        receipt += 'EDC BNI' + temp.padStart(len - 7, ' ') + '#'
      } else if (this.payments[0].payment_method == 'EDC BRI') {
        temp = this.$options.filters.number(this.edcBRIPaymentMethod)
        receipt += 'EDC BRI' + temp.padStart(len - 7, ' ') + '#'
      }

      // infaq
      if (this.infaq > 0) {
        temp = this.$options.filters.number(this.infaq)
        receipt += 'Infaq' + temp.padStart(len - 5, ' ') + '#'
      }

      // kembalian & line receipt
      if (this.kembalian > 0) {
        temp = this.$options.filters.number(this.kembalian)
        receipt += 'Kembalian' + temp.padStart(len - 9, ' ') + '#'
      }
      receipt += line2 + '#'

      // show pembayaran
      temp = this.payments2[0].payment_method
      receipt += 'Pembayaran' + temp.padStart(len - 10, ' ') + '#'
      receipt += line2 + '#'

      // cashier name & pramuniaga name
      receipt += 'Kasir : ' + this.cashier + '#'
      if (this.items.sales_person.name.length > 0) {
        receipt += 'Pramuniaga : ' + this.pramuniaga + '#'
      }

      // line berhemat & thanks
      if (this.totalAllDiscount + this.specialDiscount > 0) {
        receipt +=
          'Anda berhemat sebesar Rp.' +
          this.$options.filters.number(
            this.totalAllDiscount + this.specialDiscount
          ) +
          '#'
      }
      receipt += '----o00-Terimakasih-00o----' + '#'

      // log the receipt for validation, # will be translated to newline
      console.log(receipt)

      //---uncomment this line to print
      this.$socket.send(receipt)
    },
    calculateSpecialDisc(item) {
      return item.special_discount - item.discount
    },
    calculateDisc(item) {
      return (
        (item.discount / (item.init_price - this.calculateSpecialDisc(item))) *
        100
      )
    }
  }
}
</script>
<style scoped lang="scss">
html,
body {
  height: 100%;
}
div,
p,
table {
  line-height: 1.2em;
}
p,
table {
  margin: 0 0 0.5rem 0;
}
hr {
  margin: 0.5em 0;
}
.receipt-detail {
  width: 336px;
  margin-left: auto; /* to center the div */
  margin-right: auto; /* to center the div */
  box-sizing: border-box;
  overflow: hidden;
  font-family: Monospace;
  margin-top: 10px;
}
.alnright {
  text-align: right;
}
.alncenter {
  text-align: center;
}

@media print and (width: 336px) and(height:auto) {
  //3.5" = 8.89cm = 336px
  @page {
    size: auto 336px;
    margin: 96px; //1"
  }
  body {
    width: 336px;
  }
  .table-borderless {
    th,
    td,
    thead th,
    tbody + tbody {
      border: 0;
    }
  }
  table {
    empty-cells: show;
    width: 100%;
  }
}
@media screen and (width: 336px) and(height:auto) {
  @page {
    size: auto 336px;
  }
}
hr.line2 {
  height: 0px;
  border: 2px dashed #8c8b8b;
}
hr.line5 {
  background-color: #fff;
  border-top: 2px dashed #8c8b8b;
}
.hr-line7 {
  width: 28%;
  position: relative;
  border-bottom: 1px dashed #000000;
}
.hr-thanks {
  position: relative;
  top: 5px;
  color: #000000;
}
</style>
