<template>
  <div>
    <router-view />
  </div>
</template>

<script>
// import eventsHub from './main.js'

export default {
  name: 'App',
  beforeCreate() {
    this.$store.dispatch('setUser', {
      requestOptions: { meta: { throwError: false } }
    })
    this.axios.interceptors.request.use(request => {
      return request
    })
    this.axios.interceptors.response.use(
      response => {
        return response
      },
      error => {
        switch (error.response.status) {
          case 401:
            this.$store
              .dispatch('logout', { requestOptions: { method: 'GET' } })
              .then(() => {
                this.$toastr('error', error.response.data.message)
                this.$router.push({ name: 'login', params: '' })
              })
            break
          case 404:
            var message = error.response.data.message
            var errors = error.response.data.errors
            this.$toastr('error', message)
            for (var key in errors) {
              var err = errors[key]
              if (err.length) {
                for (const errorText of err) {
                  this.$toastr('error', errorText)
                }
              }
            }
            break
          case 504:
            this.$toastr('error', 'Timeout, Silahkan coba lagi!!!')
            break
          default:
            break
        }
        return error.response
      }
    )
  },

  created() {
    setTimeout(() => {
      if (
        this.$store.state.isAuthenticated &&
        this.$store.state.user.role == 'CASHIER'
      ) {
        let idleTimer = null
        const resetIdleTimer = () => {
          if (idleTimer) {
            clearInterval(idleTimer)
            idleTimer = null
          }
          idleTimer = setInterval(() => {
            this.logout()
          }, process.env.VUE_APP_IDLE_TIME)
        }
        document.addEventListener('mousemove', () => {
          if (!idleTimer) {
            return
          }
          resetIdleTimer()
        })
        document.addEventListener('keypress', () => {
          if (!idleTimer) {
            return
          }
          resetIdleTimer()
        })
        resetIdleTimer()
        // adda eventlistener for "idle"
        document.addEventListener('idle', () => {
          clearInterval(idleTimer)
          idleTimer = null
          console.log('You have been idle for too long. Logging out...')
        })
      }
    }, 500)
  },

  methods: {
    logout() {
      this.$store
        .dispatch('logout', { requestOptions: { method: 'POST' } })
        .then(() => {
          this.$router.go()
          this.$router.push({ name: 'login' })
        })
    }
  }
}
</script>

<style></style>
