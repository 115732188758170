/*
    Created on : Mar 27, 2018, 10:01:28 AM
    Author     : arifan rahman <arifan.rahman@engineer.com>
*/

import Vue from 'vue'

/**
 * Number, Decimal & Currency Format
 */
var numeral = require('numeral')
numeral.locale('id')

numeral.register('locale', 'id', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'Rb',
    million: 'Jt',
    billion: 'M',
    trillion: 'T'
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: 'Rp'
  }
})

numeral.register('locale', 'eng', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'K',
    million: 'Mio',
    billion: 'B',
    trillion: 'T'
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: 'IDR'
  }
})

Vue.filter('abbreviationFormat', function (value, lang) {
  numeral.locale(lang === 'en' ? 'eng' : lang)
  return numeral(value).format('$ 0.00 a')
})

Vue.filter('number', function (value) {
  return numeral(value).format('0,0')
})

Vue.filter('number2Decimal', function (value) {
  return numeral(value).format('0,0.00')
})

Vue.filter('currency', function (value) {
  return numeral(value).format('$ 0,0.')
})

Vue.filter('currency2Decimal', function (value) {
  return numeral(value).format('$ 0,0.00')
})

Vue.filter('currency2Decimal_locale', function (value, locale = null) {
  if (locale) {
    if (locale === 'IDR') {
      numeral.locale('de')
      return 'Rp' + numeral(value).format('0,0.00')
    } else if (locale === 'USD') {
      numeral.locale('en')
      return numeral(value).format('$0,0.00')
    }

    numeral.locale('de')
    return numeral(value).format('0,0.00') + ' €'
  }

  numeral.locale('de')
  return numeral(value).format('0,0.00') + ' €'
})

/**
 * Time & Date Format
 */
var moment = require('moment')
// moment.locale('id')

Vue.filter('date', function (date) {
  return moment(date).format('DD-MM-YYYY')
})

Vue.filter('dateTime', function (date) {
  return moment(date).format('YYYY MM DD HH:mm')
})

Vue.filter('fullDateTime', function (date) {
  return moment(date).format('ddd, YYYY MMMM DD HH:mm')
})

Vue.filter('shortDate', function (date) {
  return moment(date).format(' DD MMM YYYY')
})

Vue.filter('shortDay', function (date) {
  return moment(date).format(' DD MMM')
})

Vue.filter('date-transaction', function (date) {
  return moment(date).format('dddd, DD MMM YYYY @ HH.mm')
})

Vue.filter('basicDate', function (date) {
  return moment(date).format('DD-MM-YYYY')
})

Vue.filter('basicDateTime', function (date) {
  return moment(date).format('DD-MM-YYYY HH:mm:ss')
})

Vue.filter('simpleDate', function (date) {
  return moment(date).format('DD-MMM-YYYY HH:mm:ss')
})

Vue.filter('simpleDate_v2', function (date) {
  return moment(date).format('DD MMMM YYYY HH:mm:ss')
})

Vue.filter('timestamp', function (date) {
  return moment(date).format('HH:mm:ss')
})
