import Vue from 'vue'
import Router from 'vue-router'
import vueAuthInstance from './service/auth.js'
import MainLayout from './layouts/Main.vue'
import POSLayout from './layouts/pos/Main.vue'
import ReceiptLayout from './layouts/receipt/Main.vue'
import PrintLayout from './layouts/print/Main.vue'
import Login from './pages/auth/Login.vue'
import ChangePassword from './pages/profile/ChangePassword.vue'
import POS from './pages/pos/Index.vue'
import Summary from './pages/summary/Index.vue'
import Refund from './pages/refund/Index.vue'
import StockOpnameCreate from './pages/stockopname/Create.vue'
import StockOpnameReview from './pages/stockopname/Review.vue'
import StockOpnameList from './pages/stockopname/List.vue'
import StockOpnameDetail from './pages/stockopname/Detail.vue'
import Receipt from './pages/receipt/Index.vue'
import PrintSummary from './pages/print/Summary.vue'
import PrintCatalogA4 from './pages/print/CatalogA4.vue'
import PrintStockOpname from './pages/print/StockOpname.vue'
import HistoryReceipt from './pages/receipt/History.vue'
import GiftClaim from './pages/gift/Index.vue'
import CatalogList from './pages/catalog/List.vue'
import CatalogDetail from './pages/catalog/Detail.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: { name: 'login' }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { auth: false, to: 'pos' }
    },
    {
      path: '',
      component: POSLayout,
      children: [
        {
          path: '/pos',
          name: 'pos',
          component: POS,
          meta: { auth: true },
          props: true
        }
      ]
    },
    {
      path: '',
      component: MainLayout,
      children: [
        {
          path: '/change-password',
          name: 'change-password',
          component: ChangePassword,
          meta: { auth: true }
        },
        {
          path: '/summary',
          name: 'summary',
          component: Summary,
          meta: { auth: true }
        },
        {
          path: '/refund',
          name: 'refund',
          component: Refund,
          meta: { auth: true }
        },
        {
          path: '/stock-opname',
          name: 'stock-opname',
          redirect: { name: 'stock-opname-create' }
        },
        {
          path: '/stock-opname/create',
          name: 'stock-opname-create',
          component: StockOpnameCreate,
          meta: { auth: true }
        },
        {
          path: '/stock-opname/review',
          name: 'stock-opname-review',
          component: StockOpnameReview,
          meta: { auth: true }
        },
        {
          path: '/stock-opname/list',
          name: 'stock-opname-list',
          component: StockOpnameList,
          meta: { auth: true }
        },
        {
          path: '/stock-opname/detail',
          name: 'stok-opname-detail',
          component: StockOpnameDetail,
          props: true,
          meta: { auth: true }
        },
        {
          path: '/gift',
          name: 'gift-claim',
          component: GiftClaim,
          meta: { auth: true }
        },
        {
          path: '/catalog',
          name: 'catalog',
          redirect: { name: 'catalog-list' }
        },
        {
          path: '/catalog/list',
          name: 'catalog-list',
          component: CatalogList,
          meta: { auth: true }
        },
        {
          path: '/catalog/detail',
          name: 'catalog-detail',
          component: CatalogDetail,
          props: true,
          meta: { auth: true }
        }
      ]
    },
    {
      path: '',
      component: ReceiptLayout,
      children: [
        {
          path: '/receipt',
          name: 'receipt',
          component: Receipt,
          meta: { auth: true },
          props: true
        },
        {
          path: '/history-receipt',
          name: 'history-receipt',
          component: HistoryReceipt,
          meta: { auth: true },
          props: true
        },
        {
          path: '/print/stock-opname',
          name: 'print-stock-opname',
          component: PrintStockOpname,
          meta: { auth: true },
          props: true
        }
      ]
    },
    {
      path: '',
      component: PrintLayout,
      children: [
        {
          path: '/print/summary',
          name: 'print-summary',
          component: PrintSummary,
          meta: { auth: true },
          props: true
        },
        {
          path: '/print/cataloga4',
          name: 'print-catalog-a4',
          props: true,
          component: PrintCatalogA4,
          meta: { auth: true }
        }
      ]
    }
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach(function (to, from, next) {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }

  if (to.meta && to.meta.auth !== undefined) {
    if (to.meta.auth) {
      if (vueAuthInstance.isAuthenticated()) {
        next()
      } else {
        router.push({
          name: 'login'
        })
      }
    } else {
      if (vueAuthInstance.isAuthenticated()) {
        router.push({
          name: to.meta.to
        })
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
