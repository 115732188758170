<template>
  <div class="pos">
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  components: {}
}
</script>

<style scoped lang="scss">
.pos {
  overflow: hidden; /* Hide scrollbars */
}
</style>
