<template>
  <div class="login">
    <div class="kotak_login">
      <Toast />
      <center>
        <img center src="~@/assets/images/mode_logo.png" />
        <h3><b>Mode Fashion Cashier</b></h3>
      </center>
      <form @submit.prevent="doLogin" class="form-login">
        <div class="row p-fluid">
          <div class="column"></div>
          <div class="column">
            <div class="form-group row">
              <label>Email</label>
              <input
                type="email"
                class="form-control"
                aria-describedby="emailHelp"
                placeholder="Type your email here"
                v-model="form.email"
              />
            </div>
            <div class="form-group row">
              <label>Password</label>
              <input
                type="password"
                class="form-control"
                placeholder="Type your password here"
                v-model="form.password"
              />
            </div>
            <div class="form-group row">
              <button
                type="submit"
                @keyup.enter="doLogin()"
                class="btn btn-primary"
                :disabled="loading.loginButton"
              >
                Login
                <span v-if="loading.loginButton">
                  <i class="pi pi-spin pi-spinner" style="fontsize: 12px"></i>
                </span>
              </button>
              <p class="forgot-password text-right mt-2 mb-4">
                <router-link to="/forgot-password"
                  >Forgot password ?</router-link
                >
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msgNewPwd: '',
      messageemail: '',
      form: {
        email: '',
        password: ''
      },
      loading: {
        loginButton: false
      },
      app: {
        name: process.env.VUE_APP_NAME,
        version: process.env.VUE_APP_VERSION
      }
    }
  },
  watch: {
    email(value) {
      this.email = value
      this.validateEmail(value)
    },
    password(value) {
      this.password = value
      this.validatePassword(value)
    }
  },
  methods: {
    doLogin() {
      this.loading.loginButton = true
      this.$store.dispatch('login', { user: this.form }).then(response => {
        this.loading.loginButton = false
        if (response.status === 200) {
          if (response.data.data.user_info.role == 'CASHIER') {
            this.$router.go()
            setTimeout(() => {
              this.$router.push({ name: 'pos' })
            }, 300)
          } else if (response.data.data.user_info.role == 'STOCK_OPNAME') {
            this.$router.push({ name: 'stock-opname' })
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Login Gagal',
              detail: 'Data salah, silahkan input yang benar',
              life: 3000
            })
          }
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Login Gagal',
            detail: 'Data salah, silahkan input yang benar',
            life: 3000
          })
        }
      })
    },
    validatePassword(value) {
      var regexLowercase = /^(?=.*[a-z]).+$/ // Lowercase character pattern
      var regexUppercase = /^(?=.*[A-Z]).+$/ // Uppercase character pattern
      var regexNumber = /^(?=.*[\d]).+$/ // Special character or number pattern

      if (value.length != 0 && value.length < 6) {
        this.msgNewPwd = 'minimal 6 digit'
      } else if (
        !regexLowercase.test(value) ||
        !regexUppercase.test(value) ||
        !regexNumber.test(value)
      ) {
        this.msgNewPwd =
          'must contain alphanumeric, lowercase, dan uppercase letter'
      } else if (this.oldPassword == value) {
        this.msgNewPwd = 'please type different input from the old password'
      } else {
        this.msgNewPwd = ''
      }
    },
    validateEmail(value) {
      if (value.length != 0) {
        this.messageemail = ''
      }
    }
  }
}
</script>

<style scoped>
.login {
  width: 100%;
  height: 100vh;
  background: #ffffff;
  display: flex;
}

.kotak_login {
  width: 500px;
  background: white;
  height: 300px;
  /*meletakkan form ke tengah*/
  margin: 80px auto;
}

.form-login {
  border: 2px solid #ccc;
  padding: 20px 20px;
  border-style: groove;
}
</style>
